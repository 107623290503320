import axios from "axios";
import { getDataFromLocalStorage } from "../utils/LocalStore.util";
import { decrypt, encrypt } from "../utils/Encrypt";
// export const BASE_URL = "http://192.168.18.59:4001/api/v1/";
// export const BASE_URL = "http://localhost:4001/api/v1/";
export const BASE_URL = "https://server.frankme.com/api/v1/";
// export const BASE_URL = "http://192.168.18.54:5000/api/v1/";

export const getApiRequestHeader = async () => {
  /**Will get token from reducers */
  // const authtoken = JSON.parse((await getDataFromLocalStorage("token")) as any);

  const authToken = await getDataFromLocalStorage("token");
  if (authToken) {
    // console.log("Auth Token:", authToken);
  } else {
    // console.error("Auth token not found or invalid.");
  }
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: authToken ? `Bearer ${authToken}` : "",
  };
};

let instance = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
}) as any;

export const updateHeaders = async () => {
  const header = await getApiRequestHeader();
  instance.defaults.headers = header as any;
};

export const request = async ({ method, url, data, headers }: any) => {
  if (headers === undefined) {
    await updateHeaders();
  }
  const promise = instance[method](url, data);
  let response;
  try {
    response = await promise;
  } catch (error: any) {
    let decryptData = decrypt(error.response?.data);
    decryptData = JSON.parse(decryptData);
    if (typeof decryptData === "string") {
      decryptData = JSON.parse(decryptData);
    }
    return decryptData as any;
  }

  let decryptData = decrypt(response?.data);
  decryptData = JSON.parse(decryptData);
  if (typeof decryptData === "string") {
    decryptData = JSON.parse(decryptData);
  }

  return decryptData as any;
};

export const get = (url: string, permission_name: string, config: any) => {
  return request({
    method: "get",
    url,
    data: {},
    ...config,
  });
};

export const post = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  let encrypted_data = encrypt(JSON.stringify({ ...data, lang: "fr" }));
  return request({
    method: "post",
    url,
    data: {
      data: encrypted_data,
    },
    ...config,
  });
};
export const postWithoutLang = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  let encrypted_data = encrypt(JSON.stringify(data));

  return request({
    method: "post",
    url,
    data: {
      data: encrypted_data,
    },
    ...config,
  });
};

export const patch = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  let encrypted_data = encrypt(JSON.stringify({ ...data, lang: "fr" }));

  return request({
    method: "patch",
    url,
    data: {
      data: encrypted_data,
    },
    ...config,
  });
};

export async function patchfortoken(
  url: any,
  data: any,
  featureAndAction: any,
  config: any
) {
  let encrypted_data = encrypt(JSON.stringify(data));
  return request({
    method: "patch",
    url,
    data: {
      data: encrypted_data,
    },
    ...config,
  });
}

export const del = (url: string, permission_name: string, config: any) => {
  return request({
    method: "delete",
    url,
    data: {},
    ...config,
  });
};
