import { HomeIcon, PointerIcon } from "../../assets/images/FrankIcons";
import PropTypes from "prop-types";

function JobCard(props: any) {
  const { jobTitle, jobStatus, originLocation, destinationLocation } = props;
  return (
    <div
      style={{
        borderRadius: "24px",
        background: "#fff",
        width: "25%",
        flexDirection: "column",
        padding: "40px 20px 20px 20px ",
        fontFamily: "Raleway-Variable",
        gap: "20px",
        cursor: "pointer",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}
      >
        <div style={{ flex: 1, fontSize: "18px", fontWeight: "700" }}>
          {jobTitle ?? "job title"}
        </div>
        <div
          style={{
            fontFamily: "Raleway-Variable",
            fontSize: "16px",
            fontWeight: "600",
            background: "#FFF7EF",
            padding: "5px 10px 5px 10px",
            color: "#ee6031",
            borderRadius: "12px",
          }}
        >
          {jobStatus ?? "placed"}
        </div>
      </div>

      <div
        style={{
          background: "#F4F3F6",
          borderRadius: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <div style={{ display: "flex", padding: "10px" }}>
          <div style={{ flex: 1 }} className="display-flex">
            <HomeIcon />
          </div>
          <div>
            <p className="paragraph-text">Origin</p>
            <p style={{ fontSize: "18px", fontWeight: "600" }}>
              {originLocation ?? "Paris, France"}
            </p>
          </div>
        </div>

        <div style={{ display: "flex", padding: "10px" }}>
          <div style={{ flex: 1 }} className="display-flex">
            <PointerIcon />
          </div>
          <div>
            <p className="paragraph-text">Destination</p>
            <p style={{ fontSize: "18px", fontWeight: "600" }}>
              {destinationLocation ?? "Beausoleil, france"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
JobCard.defaultProps = {
  startIcon: "",
  jobStatus: "",
  jobTitle: "",
  originLocation: "",
  destinationLocation: "",
};

JobCard.propTypes = {
  startIcon: PropTypes.any,
  jobStatus: PropTypes.any,
  jobTitle: PropTypes.any,
  originLocation: PropTypes.any,
  destinationLocation: PropTypes.any,
};

export default JobCard;
