import {
  BigPackageIcon,
  EnvelopShipmentIcon,
  ExtraLargePackageIcon,
  MediumPackageIcon,
} from "../assets/images/FrankIcons";
import sofaImg from "../assets/images/FrankIcons/sofaImage.jpg";
import BracletImg from "../assets/images/FrankIcons/bracelet.jpg";
import chairTable from "../assets/images/FrankIcons/tableChairs.jpg";

import { strings } from "../Store/Localization";
import { loadStripe } from "@stripe/stripe-js";

export const apiKey = "AIzaSyCHQclQrRUyxhjnamOPm4n1z09r-KWmcdQ";
export const color_theme = {
  white: "#FFFFFF",
  pastel: "#FAFAFA",
  main_orange: "#EE6931",
  light_orange: "#F9C9B3",
  dark_blue: "#191847",
  main_blue: "#3026F1",
  black: "#1E1E1E",
  sand: "#F9D9B6",
  disabled: "#F1EFF1",
  disabled_background_figma: "#E0E0E0",
  disabled_color_figma: "#6D6D6D",

  black_10: "rgba(0, 0, 0, 0.1)", // black with 10% Opacity
  black_20: "rgba(0, 0, 0, 0.2)", // black with 20% Opacity
  black_40: "rgba(0, 0, 0, 0.4)", // black with 40% Opacity
  green: "#6FCF00",
  red: "red",
  red_2: "#790000",
  red_500: "red.500",
  card_background: "#FAFAFA",
  card_border: "#EEEEEE",
  card_active_background: "#FFEBE2",
  card_active_border: "#EE6931",
};

export const orders = [
  {
    id: 1,
    image:
      "https://frankme-23.s3.eu-central-1.amazonaws.com/dining_room_table.jpg",
    title: "Wooden Table",
    origin: "New York, NY",
    destination: "Los Angeles, CA",
    dateOfCreation: "2024-09-01",
    price: 120.5,
  },
  {
    id: 2,
    image:
      "https://frankme-23.s3.eu-central-1.amazonaws.com/dining_room_table.jpg",
    title: "Office Chair",
    origin: "San Francisco, CA",
    destination: "Seattle, WA",
    dateOfCreation: "2024-08-29",
    price: 75.0,
  },
  {
    id: 3,
    image:
      "https://frankme-23.s3.eu-central-1.amazonaws.com/pexels-mikebirdy-106839.jpg",
    title: "Coffee Table",
    origin: "Chicago, IL",
    destination: "Houston, TX",
    dateOfCreation: "2024-08-25",
    price: 95.75,
  },
  {
    id: 4,
    image:
      "https://frankme-23.s3.eu-central-1.amazonaws.com/dining_room_table.jpg",
    title: "Decorative Lamp",
    origin: "Miami, FL",
    destination: "Atlanta, GA",
    dateOfCreation: "2024-08-20",
    price: 60.0,
  },
  {
    id: 5,
    image:
      "https://frankme-23.s3.eu-central-1.amazonaws.com/pexels-mikebirdy-106839.jpg",
    title: "Bookshelf",
    origin: "Boston, MA",
    destination: "Washington, D.C.",
    dateOfCreation: "2024-08-15",
    price: 85.25,
  },
];

export const week_day_translation: any = {
  12: {
    en: "Monday",
    fr: "Lundi",
  },
  2: {
    en: "Tuesday",
    fr: "Mardi",
  },
  3: {
    en: "Wednesday",
    fr: "Mercredi",
  },
  4: {
    en: "Thursday",
    fr: "Jeudi",
  },
  5: {
    en: "Friday",
    fr: "Vendredi",
  },
  6: {
    en: "Saturday",
    fr: "Samedi",
  },
  7: {
    en: "Sunday",
    fr: "Dimanche",
  },
};

export const used_conditions = [
  {
    label: {
      en: "Almost new",
      fr: "Presque Neuf",
    },
    key: "Almost new",
  },
  {
    label: {
      en: "Fair",
      fr: "Équitable",
    },
    key: "Fair",
  },
  {
    label: {
      en: "Good",
      fr: "Bien",
    },
    key: "Good",
  },
  {
    label: {
      en: "Poor",
      fr: "Pauvre",
    },
    key: "Poor",
  },
];

export const greyStyle = [
  {
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }],
  },
  {
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [{ color: "#f5f5f5" }],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [{ color: "#bdbdbd" }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ color: "#eeeeee" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#dadada" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [{ color: "#eeeeee" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#c9c9c9" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
];

export const firebaseConfig = {
  apiKey: "AIzaSyBzhhUuUhNX_bIxzC-n6E64vUUOQ6Y5mX8",
  authDomain: "frank-423309.firebaseapp.com",
  projectId: "frank-423309",
  storageBucket: "frank-423309.firebasestorage.app",
  messagingSenderId: "881694999492",
  appId: "1:881694999492:web:72c4429bf72b8ba9b29a91",
  measurementId: "G-FTVNWEGWDB",
};

export const defaultLabelColors = {
  color_e: "#181818",
  color_f: "#666666",
  main_background: "#ffab91",
  main_blue: "#3026f1",
  main_orange: "#ee6931",
  white: "#fff",
  sand: "#F7D9B6",
  main_background_two: "#FCE6DD",
  input_feild_bg: "#F4F3F6",
  //
};
export const templeteData = [
  { paymentValue: "25.90", paymentDate: "12/01/2023", paymentStatus: "Payé" },
  { paymentValue: "101.90", paymentDate: "02/04/2023", paymentStatus: "Payé" },
  { paymentValue: "95.90", paymentDate: "19/01/2023", paymentStatus: "Payé" },
  {
    paymentValue: "125.90",
    paymentDate: "22/01/2023",
    paymentStatus: "Impayé",
  },
  { paymentValue: "80.90", paymentDate: "12/01/2023", paymentStatus: "Payé" },
  { paymentValue: "35.90", paymentDate: "11/09/2024", paymentStatus: "Impayé" },
  { paymentValue: "15.90", paymentDate: "12/09/2023", paymentStatus: "Payé" },
];
export const dateRangeFilters = [
  { label: "All Time Records", value: "all_time" },
  { label: "This Year", value: "this_year" },
  { label: "This Quarter", value: "this_quarter" },
  { label: "This Month", value: "this_month" },
  { label: "This Week", value: "this_week" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Previous Week", value: "previous_week" },
  { label: "Previous Month", value: "previous_month" },
];

export const orderStatus = [
  { label: "Accept", value: "accepted" },
  { label: "Ready", value: "ready" },
  { label: "Dispatched", value: "dispatched" },
  { label: "Delivered", value: "deliver" },
];

export const expectedReadyTime = [
  { label: "15 Minutes", value: 15 },
  { label: "30 Minutes", value: 30 },
  { label: "45 Minutes", value: 45 },
  { label: "60 Minutes", value: 60 },
];

export const spacing = {
  spacing2: 2,
  spacing3: 3,
};

export const shopCategory = [
  { label: "Jewel", value: 260265 },
  { label: "Particular", value: 303567 },
  { label: "House Keeping", value: 911328 },
  { label: "Computer Science", value: 399482 },
  { label: "DIY", value: 577483 },
  { label: "Art", value: 297519 },
  { label: "Cake", value: 714214 },
  { label: "Meal", value: 837392 },
];

export const SHIPMENT_TYPE = [
  {
    size: "Small",
    size_trans: strings.getLanguage() === "fr" ? "Petit" : "Small", // strings.small,
    desc: strings.small_desc,
    key: "regular",
    weight: "less_than_5_kg",
    level: 1,
    id: 3,
    weight_fr: "Moins de 5 kg ",
  },
  {
    size: "Medium",
    size_trans: strings.getLanguage() === "fr" ? "Moyen" : "Medium", // strings.small,
    desc: strings.medium_desc,
    key: "medium",
    weight: "between_5_and_15_kg",
    level: 2,
    id: 4,
    weight_fr: "Entre 5 et 15 kg ",
  },
  {
    size: "Large",
    size_trans: strings.getLanguage() === "fr" ? "Large" : "Large", // strings.small,
    desc: strings.large_desc,
    key: "large",
    weight: "between_15_and_25_kg",
    level: 3,
    weight_fr: "Entre 15 et 25 kg ",
    id: 1,
  },
  {
    size: "Extra Large",
    size_trans: strings.getLanguage() === "fr" ? "Extra Large" : "Extra Large", // strings.small,
    desc: strings.e_large_desc,
    key: "extra_large",
    weight: "more_than_25_kg",
    weight_fr: "Plus de 25 kg ",
    level: 4,
    id: 2,
  },
];

export const status_translations: any = {
  // Expédier
  // Reçu
  // En cours de livraison
  // Annuler
  // Livrer
  // *********
  // Payed
  // Sent
  // Cancled
  // Delivred
  // Received
  Placed: {
    fr: "En ligne",
    en: "Online",
  },
  Accepted: {
    fr: "Acceptée",
    en: "Accepted",
  },
  
  Ready: {
    fr: "Prête",
    en: "Ready",
  },
  Picked: {
    fr: "Expédiée",
    en: "Picked",
  },
  Deliver: {
    fr: "Livrée",
    en: "Delivered",
  },
  Cancelled: {
    fr: "Annuler",
    en: "Cancelled",
  },
  "In Progress": {
    fr: "En traitement",
    en: "In Progress",
  },
  Shipped: {
    fr: "En transit",
    en: "Shipped",
  },
  "Dropped at Relay": {
    fr: "Remis en Relais",
    en: "Dropped at Relay",
  },
  Rejected: {
    fr: "Réfusée",
    en: "Rejected",
  },
};


export const wallet_statuses: any = {
  wallet: {
    en: 'Wallet',
    fr: 'Porte monnaie',
  },
  requested: {
    en: 'Requested',
    fr: 'En cours',
  },
  transferred: {
    en: 'Transferred',
    fr: 'Transfert réussi',
  },
  refuse: {
    en: 'Transfer Failed',
    fr: 'Transfert refusé',
  },
};

export const getStatusColors: any = {
  Placed: {
    bg: "#f0ad4e1f",
    txt: "#F0AD4E",
  },
  Accepted: {
    bg: "#0275d81f",
    txt: "#0275D8",
  },
  Ready: {
    bg: "#5bc0de1f",
    txt: "#5BC0DE",
  },
  Picked: {
    bg: "#5bc0de1f",
    txt: "#5BC0DE",
  },
  Deliver: {
    bg: "#5cb85c1f",
    txt: "#5CB85C",
  },
  Delivered: {
    bg: "#5cb85c1f",
    txt: "#5CB85C",
  },
  Cancelled: {
    bg: "#d9534f1f",
    txt: "#D9534F",
  },
  "In Progress": {
    bg: "#0275d81f",
    txt: "#0275D8",
  },
  Shipped: {
    bg: "#0275d81f",
    txt: "#0275D8",
  },
  "Dropped at Relay": {
    bg: "#0275d81f",
    txt: "#0275D8",
  },
  Rejected: {
    bg: "#0275d81f",
    txt: "#0275D8",
  },
};
export const IDENFY_BASE_URL = "https://ivs.idenfy.com/";
export const PACKAGE_SHIPMENT_TYPE = [
  {
    label: "Urgent",
    data: "Entre 1 à 2 jours",
    type: "Urgent",
    en_type: "Urgent",
  },
  {
    label: "Je peux attendre",
    data: "Entre 3 à 7 jours",
    type: "Je peux attendre",
    en_type: "I can wait",
  },
  {
    label: "Quand mon annonce trouvera un transporteur",
    data: "Entre 8 à 30 jours",
    type: "Quand mon annonce trouvera un transporteur",
    en_type: "I have time",
  },
];
export const apiData = [
  { id: 1, label: "Electronics" },
  { id: 2, label: "Furniture" },
  { id: 3, label: "Clothing" },
  { id: 4, label: "Books" },
  { id: 5, label: "Accessories" },
  { id: 6, label: "Beauty Products" },
  { id: 7, label: "Groceries" },
  { id: 8, label: "Toys" },
  { id: 9, label: "Sporting Goods" },
  { id: 10, label: "Office Supplies" },
  { id: 11, label: "Garden Equipment" },
  // Add more items as needed
];

export const sizes_values: any = {
  small: "S",
  regular: "S",
  medium: "M",
  large: "L",
  extra_large: "XL",
};

export const item_sizes = [
  {
    size: "Envelope",
    icon: "envelop",
    desc: "Less tha 5kg",
    key: "envelope",
    weight: "less_than_5_kg",
    Icon: EnvelopShipmentIcon,
  },

  {
    size: "Medium",
    desc: "Between 30kg and 50kg",
    key: "medium",
    icon: "medium",
    weight: "between_30_and_50_kg",
    Icon: MediumPackageIcon,
  },
  {
    size: "Large",
    desc: "between 50kg and 100kg",
    icon: "large",
    key: "large",
    weight: "between_50_and_100_kg",
    Icon: BigPackageIcon,
  },
  {
    size: "Extra Large",
    desc: "More than 100kg",
    key: "extra_large",
    weight: "more_than_100_kg",
    icon: "extra_large",
    Icon: ExtraLargePackageIcon,
  },
];

export const product_info = [
  {
    id: 1,
    productImg: chairTable,
    productName: "Table + 8 ",
    productDesc:
      "Lorem ipsum dolor sit amet consectetur elit dolor sit amet consectetuer adipiscing elit",
    productAmount: "2,538,45 ",
    productType: "PARTICULIER",
  },
  {
    id: 2,
    productImg: BracletImg,
    productName: "Collier",
    productDesc:
      "Lorem ipsum dolor sit amet consectetur elit dolor sit amet consectetuer adipiscing elit",
    productAmount: "15,45",
    productType: "PROFESSIONNEL",
  },
  {
    id: 3,
    productImg: sofaImg,
    productName: "Chaise Design",
    productDesc:
      "Lorem ipsum dolor sit amet consectetur elit dolor sit amet consectetuer adipiscing elit",
    productAmount: "225,90",
    productType: "PARTICULIER",
  },
  {
    id: 4,
    productImg: chairTable,
    productName: "Table + 8 ",
    productDesc:
      "Lorem ipsum dolor sit amet consectetur elit dolor sit amet consectetuer adipiscing elit",
    productAmount: "2,538,45",
    productType: "PARTICULIER",
  },
  {
    id: 5,
    productImg: BracletImg,
    productName: "Collier",
    productDesc:
      "Lorem ipsum dolor sit amet consectetur elit dolor sit amet consectetuer adipiscing elit",
    productAmount: "15,45",
    productType: "PROFESSIONNEL",
  },
  {
    id: 6,
    productImg: sofaImg,
    productName: "Chaise Design",
    productDesc:
      "Lorem ipsum dolor sit amet consectetur elit dolor sit amet consectetuer adipiscing elit",
    productAmount: "225,90",
    productType: "PARTICULIER",
  },
];

export const TITLE_OPTION = [
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Ms.", value: "Ms." },
];

export const items_list = [
  {
    id: 2761,
    height: 515,
    length: 200,
    width: 175,
    title: "4x4 (voiture)",
    weight: "more_than_100_kg",
  },
  {
    id: 2222,
    height: 40,
    length: 160,
    width: 70,
    title: "Aile de voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2223,
    height: 12,
    length: 12,
    width: 12,
    title: "Aileron de surf",
    weight: "less_than_5_kg",
  },
  {
    id: 2224,
    height: 100,
    length: 40,
    width: 40,
    title: "Amphore romaine légalisée",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2225,
    height: 70,
    length: 40,
    width: 70,
    title: "Ampli (hifi, guitare)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2226,
    height: 122,
    length: 211,
    width: 149,
    title: "Appareil / machine de musculation",
    weight: "more_than_100_kg",
  },
  {
    id: 2227,
    height: 14,
    length: 14,
    width: 25,
    title: "Applique lumineuse",
    weight: "less_than_5_kg",
  },
  {
    id: 2228,
    height: 41,
    length: 55,
    width: 92,
    title: "Aquarium (grand)",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2229,
    height: 30,
    length: 40,
    width: 60,
    title: "Aquarium (petit)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2231,
    height: 30,
    length: 113,
    width: 30,
    title: "Arbre / arbuste en pot",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2230,
    height: 44,
    length: 142,
    width: 60,
    title: "Arbre à chat",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2232,
    height: 250,
    length: 5,
    width: 300,
    title: "Armature (voiture decapotable)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2233,
    height: 195,
    length: 57,
    width: 135,
    title: "Armoire",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2234,
    height: 74,
    length: 200,
    width: 180,
    title: "Armoire 3 portes",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2235,
    height: 135,
    length: 10,
    width: 195,
    title: "Armoire démontée",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2236,
    height: 195,
    length: 57,
    width: 135,
    title: "Armoire normande",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2237,
    height: 25,
    length: 25,
    width: 50,
    title: "Aspirateur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2238,
    height: 15,
    length: 15,
    width: 15,
    title: "Attelage de voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2239,
    height: 70,
    length: 15,
    width: 120,
    title: "Avion (modélisme, radiocommandé)",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2240,
    height: 60,
    length: 60,
    width: 1000,
    title: "Aviron",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2241,
    height: 95,
    length: 75,
    width: 150,
    title: "Babyfoot",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2242,
    height: 50,
    length: 50,
    width: 180,
    title: "Bac (fleurs, plantes)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2243,
    height: 127,
    length: 50,
    width: 128,
    title: "Bac à gravats",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2244,
    height: 150,
    length: 70,
    width: 200,
    title: "Bac de benne",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2245,
    height: 100,
    length: 50,
    width: 150,
    title: "Bac tondeuse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2246,
    height: 90,
    length: 10,
    width: 90,
    title: "Bac/receveur de douche",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2247,
    height: 100,
    length: 20,
    width: 100,
    title: "Bâche (piscine, pvc)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2248,
    height: 60,
    length: 50,
    width: 100,
    title: "Baffle",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2250,
    height: 50,
    length: 155,
    width: 110,
    title: "Bahut haut",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2251,
    height: 75,
    length: 80,
    width: 160,
    title: "Baignoire",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2252,
    height: 73,
    length: 26,
    width: 187,
    title: "Bain de soleil, transat, chaise longue",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2253,
    height: 50,
    length: 250,
    width: 58,
    title: "Balançoire de jardin",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2254,
    height: 40,
    length: 50,
    width: 106,
    title: "Banc",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2255,
    height: 160,
    length: 210,
    width: 100,
    title: "Banc de musculation",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2256,
    height: 140,
    length: 100,
    width: 150,
    title: "Banquette arrière (auto)",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2257,
    height: 140,
    length: 100,
    width: 150,
    title: "Banquette arrière (utilitaire)",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2258,
    height: 80,
    length: 92,
    width: 180,
    title: "Banquette auto",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2259,
    height: 90,
    length: 80,
    width: 140,
    title: "Banquette, canapé BZ",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2260,
    height: 105,
    length: 52,
    width: 140,
    title: "Bar",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2261,
    height: 54,
    length: 85,
    width: 54,
    title: "Barbecue (charbon, électrique)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2262,
    height: 102,
    length: 50,
    width: 140,
    title: "Barbecue à gaz",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2263,
    height: 100,
    length: 100,
    width: 200,
    title: "Barnum",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2264,
    height: 5,
    length: 5,
    width: 120,
    title: "Barres de toit de voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2265,
    height: 5,
    length: 100,
    width: 150,
    title: "Barrière de chantier",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2266,
    height: 90,
    length: 420,
    width: 150,
    title: "Bateau en polyester",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2267,
    height: 135,
    length: 60,
    width: 100,
    title: "Bateau, annexe pneumatique",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2268,
    height: 150,
    length: 100,
    width: 100,
    title: "Batterie (instrument)",
    weight: "less_than_5_kg",
  },
  {
    id: 2269,
    height: 22,
    length: 22,
    width: 51,
    title: "Batterie de tracteur",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2270,
    height: 15,
    length: 15,
    width: 25,
    title: "Batterie de voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2271,
    height: 15,
    length: 15,
    width: 25,
    title: "Batterie électronique (musique)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2272,
    height: 57,
    length: 94,
    width: 82,
    title: "Berceau",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2763,
    height: 190,
    length: 145,
    width: 485,
    title: "Berline (voiture)",
    weight: "more_than_100_kg",
  },
  {
    id: 2275,
    height: 36,
    length: 40,
    width: 56,
    title: "Bidet",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2276,
    height: 20,
    length: 10,
    width: 30,
    title: "Bidon d'huile moteur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2277,
    height: 117,
    length: 82,
    width: 213,
    title: "Billard",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2278,
    height: 60,
    length: 90,
    width: 60,
    title: "Billot",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2279,
    height: 39,
    length: 56,
    width: 78,
    title: "Bloc WC",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2280,
    height: 93,
    length: 60,
    width: 150,
    title: "Bmx",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2281,
    height: 18,
    length: 36,
    width: 18,
    title: "Bobine de câble électrique",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2282,
    height: 20,
    length: 10,
    width: 35,
    title: "Boîte à chaussures",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2283,
    height: 36,
    length: 27,
    width: 54,
    title: "Boite d'archivage",
    weight: "less_than_5_kg",
  },
  {
    id: 2284,
    height: 20,
    length: 20,
    width: 50,
    title: "Boîte de vitesse (auto)",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2285,
    height: 35,
    length: 190,
    width: 62,
    title: "Bonnetière",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2286,
    height: 70,
    length: 70,
    width: 179,
    title: "Borne arcade",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2287,
    height: 20,
    length: 10,
    width: 35,
    title: "Bougies",
    weight: "less_than_5_kg",
  },
  {
    id: 2288,
    height: 50,
    length: 50,
    width: 50,
    title: "Boule à facettes",
    weight: "less_than_5_kg",
  },
  {
    id: 2289,
    height: 30,
    length: 15,
    width: 60,
    title: "Bourriche d'huîtres",
    weight: "less_than_5_kg",
  },
  {
    id: 2290,
    height: 8,
    length: 8,
    width: 32,
    title: "Bouteille 75cl",
    weight: "less_than_5_kg",
  },
  {
    id: 2291,
    height: 50,
    length: 20,
    width: 300,
    title: "Bras de tracteur",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2764,
    height: 186,
    length: 150,
    width: 483,
    title: "Break (voiture)",
    weight: "more_than_100_kg",
  },
  {
    id: 2292,
    height: 70,
    length: 175,
    width: 100,
    title: "Brouette à moteur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2293,
    height: 95,
    length: 100,
    width: 265,
    title: "Brouette chenille",
    weight: "more_than_100_kg",
  },
  {
    id: 2294,
    height: 55,
    length: 138,
    width: 66,
    title: "Brouette de jardin",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2297,
    height: 35,
    length: 75,
    width: 135,
    title: "Buffet haut",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2300,
    height: 50,
    length: 74,
    width: 130,
    title: "Bureau d'écolier",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2301,
    height: 50,
    length: 60,
    width: 60,
    title: "Bureau enfant",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2303,
    height: 50,
    length: 20,
    width: 80,
    title: "Buste en plâtre",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2304,
    height: 90,
    length: 230,
    width: 130,
    title: "Cabine d'ascenseur",
    weight: "more_than_100_kg",
  },
  {
    id: 2305,
    height: 59,
    length: 4,
    width: 42,
    title: "Cadre A2, A3, A4",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2306,
    height: 140,
    length: 50,
    width: 190,
    title: "Cadre de lit double (140x190cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2307,
    height: 160,
    length: 50,
    width: 200,
    title: "Cadre de lit double (160x200cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2308,
    height: 180,
    length: 50,
    width: 200,
    title: "Cadre de lit double (180x200cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2309,
    height: 90,
    length: 50,
    width: 190,
    title: "Cadre de lit simple (90x190cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2310,
    height: 100,
    length: 50,
    width: 100,
    title: "Cadre de moto",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2311,
    height: 90,
    length: 90,
    width: 150,
    title: "Cadre de scooter",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2312,
    height: 75,
    length: 10,
    width: 150,
    title: "Cadre vélo (VTT, route)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2313,
    height: 45,
    length: 75,
    width: 75,
    title: "Cage à oiseaux",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2314,
    height: 80,
    length: 50,
    width: 120,
    title: "Cage pour chien",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2315,
    height: 40,
    length: 30,
    width: 60,
    title: "Cageot (fruits, légumes)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2316,
    height: 20,
    length: 20,
    width: 43,
    title: "Caisse à outils",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2317,
    height: 25,
    length: 17,
    width: 33,
    title: "Caisse de vin (6x75cl)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2318,
    height: 33,
    length: 43,
    width: 23,
    title: "Caisse enregistreuse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2319,
    height: 38,
    length: 39,
    width: 32,
    title: "Caisson de basse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2320,
    height: 50,
    length: 5,
    width: 120,
    title: "Calandre de voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2321,
    height: 185,
    length: 188,
    width: 475,
    title: "Camionnette",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2322,
    height: 90,
    length: 90,
    width: 165,
    title: "Canapé 2 places",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2323,
    height: 90,
    length: 90,
    width: 205,
    title: "Canapé 3 places",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2324,
    height: 90,
    length: 90,
    width: 250,
    title: "Canapé 4 places",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2325,
    height: 80,
    length: 100,
    width: 500,
    title: "Canapé d'angle",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2326,
    height: 100,
    length: 90,
    width: 200,
    title: "Canapé-lit, convertible",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2327,
    height: 10,
    length: 3,
    width: 200,
    title: "Canne à pêche",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2328,
    height: 71,
    length: 50,
    width: 305,
    title: "Canoë (gonflable, kayak)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2329,
    height: 38,
    length: 55,
    width: 100,
    title: "Cantine",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2330,
    height: 100,
    length: 10,
    width: 170,
    title: "Capot voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2331,
    height: 80,
    length: 122,
    width: 105,
    title: "Cardeuse",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2332,
    height: 40,
    length: 30,
    width: 70,
    title: "Carénage de scooter",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2333,
    height: 40,
    length: 18,
    width: 30,
    title: "Cartable",
    weight: "less_than_5_kg",
  },
  {
    id: 2334,
    height: 80,
    length: 1,
    width: 113,
    title: "Carte IGN rigide",
    weight: "less_than_5_kg",
  },
  {
    id: 2340,
    height: 55,
    length: 1,
    width: 55,
    title: "Carton à plat (plié)",
    weight: "less_than_5_kg",
  },
  {
    id: 2335,
    height: 25,
    length: 33,
    width: 34,
    title: "Carton bouteilles (12x33cl)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2336,
    height: 25,
    length: 17,
    width: 33,
    title: "Carton de 6 bouteilles (6x75cl)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2337,
    height: 35,
    length: 30,
    width: 55,
    title: "Carton de carrelage",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2338,
    height: 25,
    length: 17,
    width: 33,
    title: "Carton de vin (6x75cl)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2339,
    height: 28,
    length: 35,
    width: 33,
    title: "Carton de vinyles",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2341,
    height: 120,
    length: 44,
    width: 173,
    title: "Casier à vin",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2342,
    height: 58,
    length: 40,
    width: 58,
    title: "Casque de ski",
    weight: "less_than_5_kg",
  },
  {
    id: 2343,
    height: 25,
    length: 10,
    width: 41,
    title: "Cave à cigares",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2344,
    height: 60,
    length: 75,
    width: 165,
    title: "Cave à vin",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2345,
    height: 80,
    length: 80,
    width: 200,
    title: "Cercueil",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2346,
    height: 25,
    length: 20,
    width: 70,
    title: "Chaîne Hifi",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2347,
    height: 45,
    length: 100,
    width: 45,
    title: "Chaise",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2349,
    height: 72,
    length: 110,
    width: 62,
    title: "Chaise de bureau",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2350,
    height: 59,
    length: 55,
    width: 103,
    title: "Chaise de cinéma",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2348,
    height: 28,
    length: 28,
    width: 28,
    title: "Chaise d'enfant",
    weight: "less_than_5_kg",
  },
  {
    id: 2351,
    height: 46,
    length: 77,
    width: 40,
    title: "Chaise haute, bébé",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2352,
    height: 38,
    length: 60,
    width: 37,
    title: "Chaise pliante",
    weight: "less_than_5_kg",
  },
  {
    id: 2353,
    height: 201,
    length: 83,
    width: 148,
    title: "Chambre froide",
    weight: "more_than_100_kg",
  },
  {
    id: 2354,
    height: 58,
    length: 25,
    width: 58,
    title: "Chapeau",
    weight: "less_than_5_kg",
  },
  {
    id: 2355,
    height: 300,
    length: 50,
    width: 500,
    title: "Char à voile",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2357,
    height: 5,
    length: 3,
    width: 5,
    title: "Chargeur de portable",
    weight: "less_than_5_kg",
  },
  {
    id: 2356,
    height: 5,
    length: 5,
    width: 10,
    title: "Chargeur d'ordinateur",
    weight: "less_than_5_kg",
  },
  {
    id: 2358,
    height: 48,
    length: 110,
    width: 118,
    title: "Chariot de ménage",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2359,
    height: 150,
    length: 50,
    width: 170,
    title: "Chassis de karting",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2361,
    height: 100,
    length: 50,
    width: 100,
    title: "Chassis de moto",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2360,
    height: 47,
    length: 36,
    width: 76,
    title: "Chassis de tondeuse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2362,
    height: 40,
    length: 75,
    width: 45,
    title: "Chaudière",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2363,
    height: 54,
    length: 54,
    width: 117,
    title: "Chauffe eau",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2364,
    height: 60,
    length: 50,
    width: 70,
    title: "Chauffeuse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2365,
    height: 7,
    length: 5,
    width: 25,
    title: "Chaussures",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2366,
    height: 10,
    length: 40,
    width: 25,
    title: "Chaussures de ski",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2367,
    height: 23,
    length: 100,
    width: 120,
    title: "Cheminée",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2369,
    height: 140,
    length: 400,
    width: 150,
    title: "Chenillard",
    weight: "more_than_100_kg",
  },
  {
    id: 2370,
    height: 5,
    length: 70,
    width: 150,
    title: "Chevalet",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2371,
    height: 50,
    length: 60,
    width: 55,
    title: "Chevet",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2373,
    height: 100,
    length: 10,
    width: 100,
    title: "Ciel de toit auto",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2765,
    height: 175,
    length: 145,
    width: 405,
    title: "Citadine (voiture)",
    weight: "more_than_100_kg",
  },
  {
    id: 2374,
    height: 32,
    length: 10,
    width: 24,
    title: "Classeur de bureau",
    weight: "less_than_5_kg",
  },
  {
    id: 2376,
    height: 38,
    length: 2,
    width: 15,
    title: "Clavier",
    weight: "less_than_5_kg",
  },
  {
    id: 2377,
    height: 3,
    length: 1,
    width: 5,
    title: "Clé",
    weight: "less_than_5_kg",
  },
  {
    id: 2379,
    height: 66,
    length: 28,
    width: 55,
    title: "Climatiseur portable",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2380,
    height: 31,
    length: 20,
    width: 20,
    title: "Coffre",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2382,
    height: 90,
    length: 90,
    width: 130,
    title: "Coffre fort",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2383,
    height: 42,
    length: 42,
    width: 90,
    title: "Coffre jouets",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2384,
    height: 52,
    length: 40,
    width: 92,
    title: "Coiffeuse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2385,
    height: 40,
    length: 30,
    width: 100,
    title: "Colis",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2386,
    height: 40,
    length: 10,
    width: 40,
    title: "Combinaison (surf, ski, ...)",
    weight: "less_than_5_kg",
  },
  {
    id: 2388,
    height: 50,
    length: 57,
    width: 80,
    title: "Commode (petit modèle)",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2390,
    height: 59,
    length: 130,
    width: 197,
    title: "Compresseur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2391,
    height: 150,
    length: 135,
    width: 370,
    title: "Compresseur de chantier",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2392,
    height: 50,
    length: 40,
    width: 48,
    title: "Compresseur de plongée",
    weight: "less_than_5_kg",
  },
  {
    id: 2393,
    height: 80,
    length: 73,
    width: 120,
    title: "Comptoir",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2395,
    height: 43,
    length: 80,
    width: 123,
    title: "Console",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2396,
    height: 20,
    length: 10,
    width: 20,
    title: "Console de jeux vidéos",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2398,
    height: 50,
    length: 30,
    width: 30,
    title: "Corbeille",
    weight: "less_than_5_kg",
  },
  {
    id: 2399,
    height: 64,
    length: 25,
    width: 68,
    title: "Cosy",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2400,
    height: 30,
    length: 60,
    width: 100,
    title: "Couette",
    weight: "less_than_5_kg",
  },
  {
    id: 2401,
    height: 30,
    length: 30,
    width: 71,
    title: "Couffin",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2402,
    height: 10,
    length: 45,
    width: 45,
    title: "Coussin",
    weight: "less_than_5_kg",
  },
  {
    id: 2403,
    height: 25,
    length: 3,
    width: 30,
    title: "Coussin de chaise",
    weight: "less_than_5_kg",
  },
  {
    id: 2404,
    height: 50,
    length: 10,
    width: 50,
    title: "Couvre-lit",
    weight: "less_than_5_kg",
  },
  {
    id: 2405,
    height: 60,
    length: 90,
    width: 60,
    title: "Cuisinière, gazinière",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2406,
    height: 25,
    length: 25,
    width: 25,
    title: "Culasse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2408,
    height: 80,
    length: 70,
    width: 175,
    title: "Cyclomoteur/mobylette/vélomoteur",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2409,
    height: 30,
    length: 10,
    width: 150,
    title: "Débroussailleuse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2411,
    height: 18,
    length: 40,
    width: 26,
    title: "Déshumidificateur/purificateur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2412,
    height: 44,
    length: 63,
    width: 70,
    title: "Desserte",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2413,
    height: 50,
    length: 50,
    width: 100,
    title: "Détecteur de métaux",
    weight: "less_than_5_kg",
  },
  {
    id: 2414,
    height: 20,
    length: 110,
    width: 62,
    title: "Diable",
    weight: "less_than_5_kg",
  },
  {
    id: 2415,
    height: 85,
    length: 85,
    width: 183,
    title: "Distributeur automatique",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2416,
    height: 29,
    length: 1,
    width: 21,
    title: "Documents",
    weight: "less_than_5_kg",
  },
  {
    id: 2417,
    height: 29,
    length: 15,
    width: 73,
    title: "Draisienne",
    weight: "less_than_5_kg",
  },
  {
    id: 2418,
    height: 58,
    length: 236,
    width: 250,
    title: "Dressing",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2419,
    height: 61,
    length: 16,
    width: 245,
    title: "Dressing démonté",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2420,
    height: 35,
    length: 35,
    width: 35,
    title: "Drone",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2421,
    height: 4,
    length: 220,
    width: 50,
    title: "Echelle",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2422,
    height: 33,
    length: 111,
    width: 22,
    title: "Enceinte colonne",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2423,
    height: 10,
    length: 7,
    width: 15,
    title: "Enceinte home cinema",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2424,
    height: 29,
    length: 35,
    width: 40,
    title: "Enceintes Hifi, haut-parleurs",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2425,
    height: 50,
    length: 60,
    width: 240,
    title: "Enfilade",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2426,
    height: 40,
    length: 17,
    width: 41,
    title: "Enseigne de bar",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2427,
    height: 23,
    length: 1,
    width: 12,
    title: "Enveloppe",
    weight: "less_than_5_kg",
  },
  {
    id: 2428,
    height: 200,
    length: 500,
    width: 200,
    title: "Epandeur à fumier",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2429,
    height: 40,
    length: 63,
    width: 40,
    title: "Escabeau",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2430,
    height: 150,
    length: 100,
    width: 300,
    title: "Escalier de piscine",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2431,
    height: 50,
    length: 100,
    width: 110,
    title: "Etabli",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2432,
    height: 28,
    length: 200,
    width: 80,
    title: "Etagère",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2433,
    height: 60,
    length: 60,
    width: 125,
    title: "Évier",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2434,
    height: 60,
    length: 3,
    width: 32,
    title: "Façade porte cuisine",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2435,
    height: 84,
    length: 89,
    width: 74,
    title: "Fauteuil",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2436,
    height: 83,
    length: 85,
    width: 87,
    title: "Fauteuil club",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2437,
    height: 72,
    length: 110,
    width: 62,
    title: "Fauteuil de bureau",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2438,
    height: 105,
    length: 10,
    width: 100,
    title: "Fenêtre",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2439,
    height: 70,
    length: 50,
    width: 150,
    title: "Feu de circulation tricolore",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2440,
    height: 40,
    length: 40,
    width: 140,
    title: "Flightcase",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2441,
    height: 43,
    length: 109,
    width: 115,
    title: "Flightcase (grand modèle)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2442,
    height: 74,
    length: 150,
    width: 140,
    title: "Flipper",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2443,
    height: 81,
    length: 15,
    width: 335,
    title: "Flotteur de planche à voile",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2444,
    height: 60,
    length: 60,
    width: 60,
    title: "Four",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2445,
    height: 140,
    length: 80,
    width: 140,
    title: "Four à pain",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2446,
    height: 7,
    length: 3,
    width: 20,
    title: "Fourche de vélo",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2447,
    height: 53,
    length: 61,
    width: 53,
    title: "Fraiseuse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2448,
    height: 67,
    length: 179,
    width: 60,
    title: "Frigo (américain, combiné...)",
    weight: "more_than_100_kg",
  },
  {
    id: 2449,
    height: 83,
    length: 201,
    width: 148,
    title: "Frigo (pro, boucher...)",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2450,
    height: 67,
    length: 125,
    width: 60,
    title: "Frigo moyen",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2451,
    height: 60,
    length: 85,
    width: 60,
    title: "Frigo top, cube",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2452,
    height: 65,
    length: 93,
    width: 65,
    title: "Fût/Tonneau",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2453,
    height: 90,
    length: 90,
    width: 190,
    title: "Futon",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2454,
    height: 72,
    length: 37,
    width: 134,
    title: "Galerie de toit de voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2455,
    height: 146,
    length: 120,
    width: 173,
    title: "Galerie utilitaire",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2456,
    height: 10,
    length: 135,
    width: 146,
    title: "Girobroyeur",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2457,
    height: 100,
    length: 55,
    width: 100,
    title: "Girouette",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2458,
    height: 40,
    length: 35,
    width: 60,
    title: "Glacière",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2459,
    height: 35,
    length: 30,
    width: 55,
    title: "Grand carton déménagement (30x35x55 cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2460,
    height: 120,
    length: 4,
    width: 160,
    title: "Grand tableau",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2461,
    height: 70,
    length: 80,
    width: 154,
    title: "Groupe électrogène",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2462,
    height: 45,
    length: 50,
    width: 45,
    title: "Guéridon",
    weight: "less_than_5_kg",
  },
  {
    id: 2463,
    height: 50,
    length: 16,
    width: 106,
    title: "Guitare",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2464,
    height: 50,
    length: 16,
    width: 106,
    title: "Guitare basse/électrique",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2465,
    height: 10,
    length: 10,
    width: 20,
    title: "Haltères",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2466,
    height: 128,
    length: 40,
    width: 170,
    title: "Hard top",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2467,
    height: 165,
    length: 60,
    width: 190,
    title: "Hard top de 4x4, pickup",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2468,
    height: 70,
    length: 40,
    width: 120,
    title: "Hard top de cabriolet",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2469,
    height: 40,
    length: 5,
    width: 40,
    title: "Harnais de kitesurf",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2470,
    height: 30,
    length: 123,
    width: 68,
    title: "Harpe",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2471,
    height: 100,
    length: 50,
    width: 160,
    title: "Hayon de voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2472,
    height: 30,
    length: 5,
    width: 30,
    title: "Horloge",
    weight: "less_than_5_kg",
  },
  {
    id: 2473,
    height: 49,
    length: 26,
    width: 220,
    title: "Horloge comtoise",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2474,
    height: 20,
    length: 100,
    width: 60,
    title: "Hôtel à insectes",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2475,
    height: 49,
    length: 91,
    width: 60,
    title: "Hotte",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2476,
    height: 20,
    length: 2,
    width: 20,
    title: "Housse (chaise, costume, ...)",
    weight: "less_than_5_kg",
  },
  {
    id: 2477,
    height: 20,
    length: 30,
    width: 190,
    title: "Housse de snowboard",
    weight: "less_than_5_kg",
  },
  {
    id: 2478,
    height: 41,
    length: 80,
    width: 41,
    title: "Imprimante grande taille",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2479,
    height: 30,
    length: 15,
    width: 45,
    title: "Imprimante portable",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2480,
    height: 200,
    length: 150,
    width: 150,
    title: "Intérieur automobile",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2481,
    height: 4,
    length: 30,
    width: 30,
    title: "Jantes auto (17', 20')",
    weight: "less_than_5_kg",
  },
  {
    id: 2482,
    height: 5,
    length: 15,
    width: 15,
    title: "Jantes de quad",
    weight: "less_than_5_kg",
  },
  {
    id: 2483,
    height: 10,
    length: 70,
    width: 70,
    title: "Jantes et pneu",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2484,
    height: 15,
    length: 12,
    width: 50,
    title: "Jardinière",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2485,
    height: 11,
    length: 13,
    width: 11,
    title: "Jarre",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2486,
    height: 25,
    length: 13,
    width: 13,
    title: "Jeux de lumière",
    weight: "less_than_5_kg",
  },
  {
    id: 2487,
    height: 35,
    length: 52,
    width: 64,
    title: "Jouet à bascule",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2488,
    height: 90,
    length: 60,
    width: 140,
    title: "Jukebox",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2489,
    height: 100,
    length: 50,
    width: 160,
    title: "Jupe arrière (auto)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2490,
    height: 44,
    length: 176,
    width: 28,
    title: "Karcher",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2491,
    height: 100,
    length: 50,
    width: 150,
    title: "Kart",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2492,
    height: 62,
    length: 36,
    width: 88,
    title: "Kart à pédale",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2493,
    height: 71,
    length: 50,
    width: 305,
    title: "Kayak",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2494,
    height: 42,
    length: 5,
    width: 140,
    title: "Kitesurf",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2495,
    height: 45,
    length: 130,
    width: 45,
    title: "Lampadaire",
    weight: "less_than_5_kg",
  },
  {
    id: 2496,
    height: 18,
    length: 42,
    width: 18,
    title: "Lampe (bureau, chevet, ...)",
    weight: "less_than_5_kg",
  },
  {
    id: 2498,
    height: 15,
    length: 25,
    width: 15,
    title: "Lanterne",
    weight: "less_than_5_kg",
  },
  {
    id: 2501,
    height: 60,
    length: 85,
    width: 60,
    title: "Lave-vaisselle",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2502,
    height: 5,
    length: 20,
    width: 30,
    title: "Lecteur DVD/décodeur/box",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2503,
    height: 23,
    length: 1,
    width: 12,
    title: "Lettre",
    weight: "less_than_5_kg",
  },
  {
    id: 2504,
    height: 6,
    length: 6,
    width: 98,
    title: "Ligne d'échappement voiture",
    weight: "less_than_5_kg",
  },
  {
    id: 2507,
    height: 120,
    length: 90,
    width: 60,
    title: "Lit bébé",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2508,
    height: 120,
    length: 10,
    width: 60,
    title: "Lit bébé démonté",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2509,
    height: 140,
    length: 50,
    width: 190,
    title: "Lit double (140x190cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2510,
    height: 160,
    length: 50,
    width: 200,
    title: "Lit double (160x200cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2511,
    height: 180,
    length: 50,
    width: 200,
    title: "Lit double (180x200cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2512,
    height: 97,
    length: 186,
    width: 208,
    title: "Lit mezzanine",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2513,
    height: 97,
    length: 70,
    width: 208,
    title: "Lit mezzanine démonté",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2514,
    height: 20,
    length: 76,
    width: 20,
    title: "Lit parapluie, pliable",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2515,
    height: 90,
    length: 50,
    width: 190,
    title: "Lit simple (90x190cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2516,
    height: 143,
    length: 200,
    width: 105,
    title: "Lit superposé",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2517,
    height: 45,
    length: 200,
    width: 105,
    title: "Lit superposé démonté",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2518,
    height: 43,
    length: 12,
    width: 89,
    title: "Luge",
    weight: "less_than_5_kg",
  },
  {
    id: 2520,
    height: 20,
    length: 35,
    width: 20,
    title: "Machine (café, jus, pizza, ...)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2521,
    height: 133,
    length: 112,
    width: 151,
    title: "Machine à bois",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2522,
    height: 18,
    length: 29,
    width: 37,
    title: "Machine à coudre",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2523,
    height: 60,
    length: 76,
    width: 190,
    title: "Machine à sous",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2524,
    height: 21,
    length: 1,
    width: 29,
    title: "Magazine, revue",
    weight: "less_than_5_kg",
  },
  {
    id: 2525,
    height: 34,
    length: 115,
    width: 74,
    title: "Maison de poupées",
    weight: "less_than_5_kg",
  },
  {
    id: 2526,
    height: 48,
    length: 16,
    width: 37,
    title: "Malette",
    weight: "less_than_5_kg",
  },
  {
    id: 2527,
    height: 44,
    length: 42,
    width: 78,
    title: "Malle",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2528,
    height: 75,
    length: 80,
    width: 180,
    title: "Malle (grand modèle)",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2529,
    height: 30,
    length: 40,
    width: 70,
    title: "Mannequin/buste de couture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2530,
    height: 100,
    length: 100,
    width: 200,
    title: "Maquette (grand modèle)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2531,
    height: 125,
    length: 80,
    width: 180,
    title: "Marquise en fer forgé",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2532,
    height: 10,
    length: 10,
    width: 210,
    title: "Mat de planche à voile",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2533,
    height: 190,
    length: 25,
    width: 90,
    title: "Matelas 1 place (90x190cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2534,
    height: 190,
    length: 25,
    width: 140,
    title: "Matelas 2 places (140x190cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2535,
    height: 200,
    length: 25,
    width: 160,
    title: "Matelas 2 places (160x200cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2536,
    height: 200,
    length: 25,
    width: 180,
    title: "Matelas 2 places (180x200cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2537,
    height: 20,
    length: 20,
    width: 60,
    title: "Matelas gonflable",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2539,
    height: 50,
    length: 50,
    width: 200,
    title: "Meuble à chaussures",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2541,
    height: 40,
    length: 40,
    width: 180,
    title: "Meuble TV",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2542,
    height: 50,
    length: 28,
    width: 38,
    title: "Micro-ondes",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2543,
    height: 30,
    length: 30,
    width: 100,
    title: "Microscope médical",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2544,
    height: 50,
    length: 40,
    width: 40,
    title: "Mini bar, mini frigo",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2545,
    height: 40,
    length: 34,
    width: 54,
    title: "Mini four",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2547,
    height: 54,
    length: 10,
    width: 54,
    title: "Miroir rond",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2772,
    height: 173,
    length: 10,
    width: 55,
    title: "Miroir sur pied",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2548,
    height: 250,
    length: 20,
    width: 145,
    title: "Miroir trumeau",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2767,
    height: 186,
    length: 166,
    width: 465,
    title: "Monospace (voiture)",
    weight: "more_than_100_kg",
  },
  {
    id: 2550,
    height: 50,
    length: 60,
    width: 40,
    title: "Moteur auto",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2551,
    height: 30,
    length: 30,
    width: 100,
    title: "Moteur bateau (hors bord, zodiac)",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2553,
    height: 75,
    length: 81,
    width: 215,
    title: "Moto",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2720,
    height: 75,
    length: 190,
    width: 105,
    title: "Moto 125 cc",
    weight: "more_than_100_kg",
  },
  {
    id: 2554,
    height: 60,
    length: 70,
    width: 110,
    title: "Moto enfant",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2556,
    height: 50,
    length: 50,
    width: 50,
    title: "Nettoyeur vapeur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2557,
    height: 80,
    length: 90,
    width: 120,
    title: "Niche",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2558,
    height: 7,
    length: 7,
    width: 15,
    title: "Objectif d'appareil photo",
    weight: "less_than_5_kg",
  },
  {
    id: 2559,
    height: 35,
    length: 20,
    width: 50,
    title: "Ordinateur de bureau, fixe",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2560,
    height: 36,
    length: 10,
    width: 45,
    title: "Ordinateur portable",
    weight: "less_than_5_kg",
  },
  {
    id: 2561,
    height: 40,
    length: 8,
    width: 40,
    title: "Oreiller",
    weight: "less_than_5_kg",
  },
  {
    id: 2806,
    height: 45,
    length: 135,
    width: 45,
    title: "Paire de chaises (empilées l'une sur l'autre)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2563,
    height: 80,
    length: 15,
    width: 120,
    title: "Palette",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2564,
    height: 50,
    length: 100,
    width: 50,
    title: "Panier à linge",
    weight: "less_than_5_kg",
  },
  {
    id: 2565,
    height: 200,
    length: 6,
    width: 80,
    title: "Panneau (PVC, bois)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2566,
    height: 100,
    length: 10,
    width: 200,
    title: "Panneau solaire",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2567,
    height: 105,
    length: 5,
    width: 68,
    title: "Paper board",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2568,
    height: 50,
    length: 30,
    width: 50,
    title: "Paquet de carrelage",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2569,
    height: 20,
    length: 8,
    width: 120,
    title: "Paquet de parquet stratifié",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2570,
    height: 58,
    length: 63,
    width: 58,
    title: "Parabole satellite",
    weight: "less_than_5_kg",
  },
  {
    id: 2571,
    height: 50,
    length: 50,
    width: 100,
    title: "Paramoteur, parapente",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2572,
    height: 30,
    length: 30,
    width: 250,
    title: "Parasol",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2573,
    height: 40,
    length: 10,
    width: 175,
    title: "Paravent",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2574,
    height: 90,
    length: 75,
    width: 107,
    title: "Parc bébé",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2575,
    height: 90,
    length: 10,
    width: 107,
    title: "Parc bébé plié",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2576,
    height: 130,
    length: 20,
    width: 120,
    title: "Pare-choc de voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2577,
    height: 5,
    length: 1,
    width: 10,
    title: "Passeport",
    weight: "less_than_5_kg",
  },
  {
    id: 2578,
    height: 7,
    length: 7,
    width: 15,
    title: "Pédale de guitare",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2579,
    height: 15,
    length: 15,
    width: 15,
    title: "Peluche",
    weight: "less_than_5_kg",
  },
  {
    id: 2580,
    height: 40,
    length: 100,
    width: 60,
    title: "Peluche géante",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2581,
    height: 58,
    length: 185,
    width: 90,
    title: "Penderie",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2582,
    height: 45,
    length: 21,
    width: 59,
    title: "Perceuse d'établi",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2583,
    height: 100,
    length: 100,
    width: 200,
    title: "Pergola",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2584,
    height: 28,
    length: 35,
    width: 30,
    title: "Petit carton déménagement (35x28x30 cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2585,
    height: 60,
    length: 60,
    width: 60,
    title: "Petit électroménager",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2586,
    height: 60,
    length: 78,
    width: 60,
    title: "Petit fauteuil",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2588,
    height: 17,
    length: 6,
    width: 16,
    title: "Phare de voiture",
    weight: "less_than_5_kg",
  },
  {
    id: 2589,
    height: 65,
    length: 84,
    width: 61,
    title: "Photocopieur",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2590,
    height: 70,
    length: 131,
    width: 154,
    title: "Piano (droit)",
    weight: "more_than_100_kg",
  },
  {
    id: 2591,
    height: 150,
    length: 102,
    width: 155,
    title: "Piano 1/4 de queue",
    weight: "more_than_100_kg",
  },
  {
    id: 2592,
    height: 160,
    length: 103,
    width: 275,
    title: "Piano à queue",
    weight: "more_than_100_kg",
  },
  {
    id: 2593,
    height: 31,
    length: 10,
    width: 95,
    title: "Piano électrique (synthétiseur)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2594,
    height: 30,
    length: 15,
    width: 50,
    title: "Pièces détachées de vélo",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2595,
    height: 100,
    length: 12,
    width: 250,
    title: "Pierre tombale",
    weight: "less_than_5_kg",
  },
  {
    id: 2596,
    height: 60,
    length: 4,
    width: 246,
    title: "Plan de travail",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2597,
    height: 30,
    length: 20,
    width: 60,
    title: "Plancha",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2598,
    height: 76,
    length: 15,
    width: 330,
    title: "Planche de paddle",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2599,
    height: 50,
    length: 8,
    width: 200,
    title: "Planche de surf",
    weight: "less_than_5_kg",
  },
  {
    id: 2600,
    height: 300,
    length: 10,
    width: 145,
    title: "Planeur (modélisme)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2601,
    height: 20,
    length: 150,
    width: 20,
    title: "Plante (grande)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2602,
    height: 5,
    length: 10,
    width: 5,
    title: "Plante (petite)",
    weight: "less_than_5_kg",
  },
  {
    id: 2603,
    height: 60,
    length: 5,
    width: 60,
    title: "Plaque de cuisson (vitro, induction)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2604,
    height: 30,
    length: 20,
    width: 100,
    title: "Plateau de coupe de tondeuse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2605,
    height: 40,
    length: 75,
    width: 120,
    title: "Plateau de table",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2606,
    height: 12,
    length: 33,
    width: 41,
    title: "Platine vinyle",
    weight: "less_than_5_kg",
  },
  {
    id: 2607,
    height: 70,
    length: 10,
    width: 70,
    title: "Pneu (auto)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2608,
    height: 85,
    length: 52,
    width: 85,
    title: "Pneu (tracteur)",
    weight: "more_than_100_kg",
  },
  {
    id: 2609,
    height: 110,
    length: 50,
    width: 110,
    title: "Pneu de camion (poids lourd)",
    weight: "more_than_100_kg",
  },
  {
    id: 2610,
    height: 7,
    length: 5,
    width: 10,
    title: "Pneu de karting",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2611,
    height: 45,
    length: 90,
    width: 83,
    title: "Poële à bois, granulés",
    weight: "more_than_100_kg",
  },
  {
    id: 2612,
    height: 35,
    length: 47,
    width: 45,
    title: "Poële à fioul, pétrole",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2613,
    height: 70,
    length: 185,
    width: 65,
    title: "Pompe à chaleur",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2614,
    height: 50,
    length: 150,
    width: 50,
    title: "Pompe à essence",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2615,
    height: 50,
    length: 283,
    width: 338,
    title: "Pont de voiture",
    weight: "more_than_100_kg",
  },
  {
    id: 2616,
    height: 10,
    length: 200,
    width: 300,
    title: "Portail",
    weight: "more_than_100_kg",
  },
  {
    id: 2618,
    height: 15,
    length: 200,
    width: 240,
    title: "Porte de garage",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2619,
    height: 230,
    length: 10,
    width: 73,
    title: "Porte de placard",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2620,
    height: 50,
    length: 108,
    width: 50,
    title: "Porte manteau (à pied)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2621,
    height: 41,
    length: 10,
    width: 58,
    title: "Porte ski",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2622,
    height: 33,
    length: 25,
    width: 132,
    title: "Porte vélo",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2623,
    height: 97,
    length: 20,
    width: 208,
    title: "Porte vitrée/ porte fenêtre",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2624,
    height: 60,
    length: 40,
    width: 140,
    title: "Portière de tracteur",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2625,
    height: 35,
    length: 135,
    width: 135,
    title: "Portière de véhicule utilitaire",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2626,
    height: 20,
    length: 110,
    width: 110,
    title: "Portière de voiture",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2627,
    height: 55,
    length: 60,
    width: 55,
    title: "Pot de fleurs (grand)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2628,
    height: 7,
    length: 8,
    width: 7,
    title: "Pot de fleurs (petit)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2629,
    height: 15,
    length: 20,
    width: 15,
    title: "Pot de peinture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2631,
    height: 75,
    length: 100,
    width: 75,
    title: "Pouf poire",
    weight: "less_than_5_kg",
  },
  {
    id: 2632,
    height: 108,
    length: 28,
    width: 34,
    title: "Poussette canne",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2633,
    height: 120,
    length: 63,
    width: 38,
    title: "Poussette double",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2634,
    height: 110,
    length: 75,
    width: 120,
    title: "Poussette évolutive trio",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2635,
    height: 200,
    length: 30,
    width: 130,
    title: "Présentoir",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2636,
    height: 37,
    length: 80,
    width: 180,
    title: "Punching ball",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2637,
    height: 120,
    length: 115,
    width: 200,
    title: "Quad",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2638,
    height: 63,
    length: 71,
    width: 104,
    title: "Quad enfant",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2639,
    height: 60,
    length: 12,
    width: 70,
    title: "Radiateur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2640,
    height: 20,
    length: 10,
    width: 20,
    title: "Radio",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2641,
    height: 48,
    length: 5,
    width: 85,
    title: "Rallonge de table",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2642,
    height: 7,
    length: 90,
    width: 40,
    title: "Rambarde",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2643,
    height: 61,
    length: 113,
    width: 244,
    title: "Rameur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2646,
    height: 80,
    length: 5,
    width: 200,
    title: "Rampe de chargement",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2644,
    height: 8,
    length: 3,
    width: 120,
    title: "Rampe d'éclairage",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2645,
    height: 90,
    length: 5,
    width: 300,
    title: "Rampe d'escalier",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2647,
    height: 67,
    length: 125,
    width: 60,
    title: "Réfrigérateur",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2648,
    height: 67,
    length: 179,
    width: 60,
    title: "Réfrigérateur (américain, combiné...)",
    weight: "more_than_100_kg",
  },
  {
    id: 2649,
    height: 83,
    length: 201,
    width: 148,
    title: "Réfrigérateur (pro, boucher...)",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2650,
    height: 60,
    length: 85,
    width: 60,
    title: "Réfrigérateur top, cube",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2651,
    height: 78,
    length: 51,
    width: 150,
    title: "Remorque",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2653,
    height: 60,
    length: 35,
    width: 80,
    title: "Remorque de vélo",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2654,
    height: 228,
    length: 89,
    width: 135,
    title: "Remorque de voiture",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2652,
    height: 60,
    length: 310,
    width: 155,
    title: "Remorque porte-moto",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2655,
    height: 40,
    length: 40,
    width: 70,
    title: "Repose-pieds",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2656,
    height: 40,
    length: 5,
    width: 40,
    title: "Rideau",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2657,
    height: 20,
    length: 20,
    width: 80,
    title: "Rideau électrique (alu, ...)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2658,
    height: 50,
    length: 49,
    width: 60,
    title: "Robot piscine",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2659,
    height: 107,
    length: 102,
    width: 70,
    title: "Rocking chair",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2660,
    height: 70,
    length: 25,
    width: 70,
    title: "Roue (4x4, utilitaire)",
    weight: "more_than_100_kg",
  },
  {
    id: 2661,
    height: 59,
    length: 20,
    width: 59,
    title: "Roue avec pneu (auto)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2662,
    height: 59,
    length: 5,
    width: 59,
    title: "Roue de vélo",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2663,
    height: 20,
    length: 20,
    width: 110,
    title: "Rouleau de bache plastique",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2664,
    height: 10,
    length: 10,
    width: 30,
    title: "Rouleau de gaine",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2665,
    height: 20,
    length: 20,
    width: 100,
    title: "Rouleau de gazon, laine de verre",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2666,
    height: 15,
    length: 15,
    width: 110,
    title: "Rouleau de tissu",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2667,
    height: 94,
    length: 165,
    width: 109,
    title: "Sableuse",
    weight: "more_than_100_kg",
  },
  {
    id: 2668,
    height: 20,
    length: 55,
    width: 39,
    title: "Sac à dos",
    weight: "less_than_5_kg",
  },
  {
    id: 2669,
    height: 35,
    length: 35,
    width: 40,
    title: "Sac cabas",
    weight: "less_than_5_kg",
  },
  {
    id: 2670,
    height: 20,
    length: 20,
    width: 40,
    title: "Sac de couchage",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2671,
    height: 60,
    length: 15,
    width: 40,
    title: "Sac de randonnée",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2672,
    height: 40,
    length: 20,
    width: 100,
    title: "Sac de sport, voyage",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2673,
    height: 25,
    length: 8,
    width: 40,
    title: "Sacoche de Pc portable",
    weight: "less_than_5_kg",
  },
  {
    id: 2674,
    height: 96,
    length: 325,
    width: 96,
    title: "Salon de jardin",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2675,
    height: 110,
    length: 190,
    width: 153,
    title: "Sauna",
    weight: "more_than_100_kg",
  },
  {
    id: 2676,
    height: 79,
    length: 20,
    width: 140,
    title: "Saxophone",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2677,
    height: 15,
    length: 3,
    width: 47,
    title: "Scie à métaux",
    weight: "less_than_5_kg",
  },
  {
    id: 2678,
    height: 36,
    length: 53,
    width: 53,
    title: "Scie circulaire",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2679,
    height: 78,
    length: 134,
    width: 222,
    title: "Scooter > 250 cc",
    weight: "more_than_100_kg",
  },
  {
    id: 2680,
    height: 100,
    length: 102,
    width: 200,
    title: "Scooter 125 cc",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2771,
    height: 145,
    length: 215,
    width: 78,
    title: "Scooter 3 roues",
    weight: "more_than_100_kg",
  },
  {
    id: 2681,
    height: 71,
    length: 102,
    width: 175,
    title: "Scooter 50 cc",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2682,
    height: 25,
    length: 44,
    width: 25,
    title: "Sculpture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2685,
    height: 44,
    length: 50,
    width: 44,
    title: "Selle de cheval",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2686,
    height: 40,
    length: 120,
    width: 60,
    title: "Semainier",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2687,
    height: 10,
    length: 3,
    width: 20,
    title: "Serpillère",
    weight: "less_than_5_kg",
  },
  {
    id: 2688,
    height: 3,
    length: 10,
    width: 7,
    title: "Serrure",
    weight: "less_than_5_kg",
  },
  {
    id: 2689,
    height: 35,
    length: 30,
    width: 55,
    title: "Service de table",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2690,
    height: 46,
    length: 71,
    width: 44,
    title: "Siège bébé, siège auto",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2691,
    height: 47,
    length: 40,
    width: 107,
    title: "Siège de voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2692,
    height: 15,
    length: 15,
    width: 180,
    title: "Skis",
    weight: "less_than_5_kg",
  },
  {
    id: 2693,
    height: 48,
    length: 10,
    width: 159,
    title: "Snowboard",
    weight: "less_than_5_kg",
  },
  {
    id: 2694,
    height: 120,
    length: 15,
    width: 200,
    title: "Sommier 1 place (90/120x190cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2695,
    height: 140,
    length: 15,
    width: 190,
    title: "Sommier 2 places (140x190cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2773,
    height: 20,
    length: 160,
    width: 200,
    title: "Sommier 2 places (160x200cm)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2696,
    height: 40,
    length: 15,
    width: 120,
    title: "Soufflet de forgeron",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2698,
    height: 63,
    length: 5,
    width: 10,
    title: "Store",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2699,
    height: 200,
    length: 5,
    width: 250,
    title: "Store banne",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2700,
    height: 190,
    length: 15,
    width: 140,
    title: "Structure de lit, bois de lit",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2701,
    height: 100,
    length: 75,
    width: 100,
    title: "Table",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2702,
    height: 100,
    length: 78,
    width: 220,
    title: "Table (grand modèle)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2703,
    height: 62,
    length: 69,
    width: 118,
    title: "Table à dessin, d'achitecte",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2704,
    height: 54,
    length: 93,
    width: 82,
    title: "Table à langer",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2705,
    height: 75,
    length: 75,
    width: 180,
    title: "Table à manger",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2706,
    height: 38,
    length: 10,
    width: 122,
    title: "Table à repasser",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2707,
    height: 55,
    length: 33,
    width: 110,
    title: "Table basse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2708,
    height: 75,
    length: 86,
    width: 150,
    title: "Table de soudure",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2709,
    height: 42,
    length: 45,
    width: 42,
    title: "Tabouret",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2710,
    height: 43,
    length: 80,
    width: 43,
    title: "Tabouret de bar",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2711,
    height: 90,
    length: 5,
    width: 200,
    title: "Tatami",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2712,
    height: 1,
    length: 12,
    width: 7,
    title: "Téléphone portable",
    weight: "less_than_5_kg",
  },
  {
    id: 2713,
    height: 100,
    length: 15,
    width: 100,
    title: "Tente",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2714,
    height: 123,
    length: 28,
    width: 215,
    title: "Tente de toit",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2715,
    height: 74,
    length: 36,
    width: 122,
    title: "Théâtre de marionnettes",
    weight: "less_than_5_kg",
  },
  {
    id: 2716,
    height: 50,
    length: 15,
    width: 40,
    title: "Tiroir",
    weight: "less_than_5_kg",
  },
  {
    id: 2717,
    height: 10,
    length: 1,
    width: 100,
    title: "Toise",
    weight: "less_than_5_kg",
  },
  {
    id: 2718,
    height: 73,
    length: 108,
    width: 115,
    title: "Tondeuse",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2719,
    height: 65,
    length: 93,
    width: 56,
    title: "Tonneau de vin",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2721,
    height: 50,
    length: 130,
    width: 150,
    title: "Tour de cheminée",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2722,
    height: 34,
    length: 37,
    width: 47,
    title: "Tour de potier",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2723,
    height: 270,
    length: 510,
    width: 320,
    title: "Tracteur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2724,
    height: 116,
    length: 90,
    width: 205,
    title: "Tracteur tondeuse",
    weight: "more_than_100_kg",
  },
  {
    id: 2725,
    height: 40,
    length: 36,
    width: 49,
    title: "Trancheur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2726,
    height: 77,
    length: 42,
    width: 10,
    title: "Transat bébé",
    weight: "less_than_5_kg",
  },
  {
    id: 2727,
    height: 37,
    length: 74,
    width: 74,
    title: "Tréteau",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2728,
    height: 50,
    length: 70,
    width: 100,
    title: "Treuil",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2729,
    height: 38,
    length: 37,
    width: 60,
    title: "Tricycle",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2730,
    height: 4,
    length: 150,
    width: 4,
    title: "Tringle",
    weight: "less_than_5_kg",
  },
  {
    id: 2731,
    height: 51,
    length: 110,
    width: 209,
    title: "Triporteur, vélo cargo",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2732,
    height: 25,
    length: 30,
    width: 26,
    title: "Tronçonneuse",
    weight: "less_than_5_kg",
  },
  {
    id: 2733,
    height: 60,
    length: 15,
    width: 90,
    title: "Trottinette",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2734,
    height: 101,
    length: 64,
    width: 135,
    title: "Trottinette électrique",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2735,
    height: 123,
    length: 145,
    width: 234,
    title: "Twizy",
    weight: "more_than_100_kg",
  },
  {
    id: 2736,
    height: 28,
    length: 10,
    width: 31,
    title: "Unité centrale d'ordinateur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2737,
    height: 150,
    length: 100,
    width: 150,
    title: "Unité de climatisation",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2738,
    height: 30,
    length: 200,
    width: 120,
    title: "Vaisselier",
    weight: "between_50_and_100_kg",
  },
  {
    id: 2739,
    height: 35,
    length: 30,
    width: 55,
    title: "Vaisselle",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2740,
    height: 75,
    length: 32,
    width: 50,
    title: "Valise",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2741,
    height: 20,
    length: 55,
    width: 39,
    title: "Valise cabine",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2742,
    height: 394,
    length: 174,
    width: 230,
    title: "Van à chevaux",
    weight: "more_than_100_kg",
  },
  {
    id: 2743,
    height: 16,
    length: 22,
    width: 16,
    title: "Vase",
    weight: "less_than_5_kg",
  },
  {
    id: 2744,
    height: 46,
    length: 15,
    width: 61,
    title: "Vasque de salle de bains",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2745,
    height: 185,
    length: 188,
    width: 475,
    title: "Véhicule utilitaire",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2746,
    height: 80,
    length: 150,
    width: 30,
    title: "Vélo (vtt, ville, course, ...)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2747,
    height: 60,
    length: 90,
    width: 255,
    title: "Vélo biporteur, cargo",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2748,
    height: 58,
    length: 121,
    width: 94,
    title: "Vélo d'appartement",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2749,
    height: 70,
    length: 161,
    width: 129,
    title: "Vélo elliptique",
    weight: "between_30_and_50_kg",
  },
  {
    id: 2750,
    height: 80,
    length: 30,
    width: 145,
    title: "Vélo enfant",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2751,
    height: 35,
    length: 65,
    width: 75,
    title: "Vélo pliable",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2752,
    height: 100,
    length: 50,
    width: 150,
    title: "Veloski",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2753,
    height: 100,
    length: 50,
    width: 100,
    title: "Ventilateur",
    weight: "less_than_5_kg",
  },
  {
    id: 2754,
    height: 25,
    length: 16,
    width: 80,
    title: "Violon",
    weight: "less_than_5_kg",
  },
  {
    id: 2755,
    height: 50,
    length: 40,
    width: 130,
    title: "Violoncelle",
    weight: "less_than_5_kg",
  },
  {
    id: 2756,
    height: 69,
    length: 3,
    width: 136,
    title: "Vitre de tracteur",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2757,
    height: 60,
    length: 4,
    width: 50,
    title: "Vitre de voiture",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2758,
    height: 46,
    length: 180,
    width: 80,
    title: "Vitrine",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2759,
    height: 70,
    length: 75,
    width: 180,
    title: "Vitrine réfrigérée",
    weight: "less_than_5_kg",
  },
  {
    id: 2760,
    height: 150,
    length: 15,
    width: 300,
    title: "Voile de kite/wind surf",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2762,
    height: 57,
    length: 42,
    width: 81,
    title: "Voiture à pédales",
    weight: "less_than_5_kg",
  },
  {
    id: 2766,
    height: 74,
    length: 74,
    width: 133,
    title: "Voiture électrique enfant (jouet)",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2768,
    height: 64,
    length: 30,
    width: 380,
    title: "Volet",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2769,
    height: 220,
    length: 200,
    width: 200,
    title: "Volière",
    weight: "between_5_and_30_kg",
  },
  {
    id: 2770,
    height: 135,
    length: 50,
    width: 200,
    title: "Zodiac",
    weight: "between_30_and_50_kg",
  },
];

export const fillColorArray = [
  "#f17a45",
  "#f17a45",
  "#f19745",
  "#f19745",
  "#f1a545",
  "#f1a545",
  "#f1b345",
  "#f1b345",
  "#f1d045",
  "#f1d045",
];

export const GOOGLE_CLIENT_ID =
  "881694999492-6v6f6b979a6amn8oo4125sjvfiqelmgj.apps.googleusercontent.com";
export const GOOGLE_CLIENT_SECRET = "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";
export const FACEBOOK_APP_ID = "462484816170367";
export const FACEBOOK_CLIENT_TOKEN = "f3fd72774a910390822290181a53ce07";
export const images = [
  {
    imgURL:
      "https://gratisography.com/wp-content/uploads/2024/03/gratisography-vr-glasses-1170x780.jpg",
    imgAlt: "img-1",
    text: "abc",
  },
  {
    imgURL:
      "https://gratisography.com/wp-content/uploads/2024/03/gratisography-vr-glasses-1170x780.jpg",
    imgAlt: "img-2",
    text: "abc",
  },
  {
    imgURL:
      "https://gratisography.com/wp-content/uploads/2024/03/gratisography-vr-glasses-1170x780.jpg",
    imgAlt: "img-3",
    text: "abc",
  },
];

export const no_image_url =
  "https://frankme-23.s3.eu-central-1.amazonaws.com/no-image.jpg";

export const stripePromise = loadStripe(
  "pk_test_51PHLwERrtK56w4VJovEUBRsfDb0K90Mo3tt7QrypEedKpj9HuTszcu8bnI8SnXsmA7EWSwZ87LorkcfAHi7zXz4l00kogJUBL5"
  // "pk_live_51PHLwERrtK56w4VJcMQLW2zbn7LTZPNZnBLUmFdMIakX5v5HKsPRllmYUMmWJXvmPVJaptcEN8uxmQdzDNVJYFUC0050uiD5gE"
);
