import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import footer from "../../assets/images/FrankIcons/NewFooter.png";
import { defaultLabelColors } from "../../utils/constants";
import googlePlayBtn from "../../assets/mobileImages/french-google-play.png";
import { AppStoreFR, FrankIcon } from "../../assets/images/FrankIcons";
import { useNavigate } from "react-router-dom";

function CustomFooter(props: any) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="frame-175 display-flex"
        style={{ position: "relative", marginTop: "245px" }}
      >
        <div
          style={{
            borderRadius: "20px",
            zIndex: 1111,
            width: "1000px",
            position: "absolute",
            top: -228,
          }}
        >
          <img
            src={footer}
            alt="footer-img"
            style={{
              border: props?.showBorder ? "1px solid #fff" : "none",
              borderRadius: "50px",
            }}
          />
          <div style={{ position: "absolute", bottom: 80, right: "-43%" }}>
            <h3
              style={{
                fontSize: "36px",
                fontWeight: "900",
                fontFamily: "Raleway-Italic",
                color: defaultLabelColors.white,
              }}
            >
              Téléchargez l'application <br />
              mobile Frank
            </h3>
            <p
              style={{
                color: defaultLabelColors.white,
                fontSize: 24,
                fontFamily: "Raleway-Italic",
              }}
            >
              Rejoignez gratuitement la communauté
              <br /> de livraison verte
            </p>
            <div style={{ display: "flex" }}>
              <a
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.frankc2c"
                target="_blank"
                style={{ width: 160 }}
              >
                <img
                  src={googlePlayBtn}
                  style={{ height: "50px", width: "160px" }}
                  alt="google-play"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/frank/id6502341979"
                target="_blank"
                style={{ width: 160, marginLeft: "10px" }}
              >
                <AppStoreFR />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-new">
          <div className="contain">
            <div className="div-line-123" style={{ marginBottom: "5px" }}></div>

            <div className="col">
              <p>
                {" "}
                <FrankIcon fill={"#fff"} height={60} />
              </p>
            </div>
            <div className="col">
              <span
                style={{
                  padding: 0,
                  fontSize: "14px",
                  color: "#fff",
                  fontFamily: "Raleway-Extra-Bold-Italic",
                }}
              >
                Particulier
              </span>

              <ul>
                <li
                  onClick={() => {
                    navigate("/send-package");
                  }}
                >
                  Envoyer un colis
                </li>
                {/*
                // coment by asad iqbal from 16/1/25(Review website + APP ppt)
                <li
                  onClick={() => {
                    navigate("/transporter-un-colis");
                  }}
                >
                  Transporteur
                </li> */}
                <li
                  onClick={() => {
                    navigate("/transporter-un-colis");
                  }}
                >
                  Devenir transporteur
                </li>
                <li
                  onClick={() => {
                    navigate("/send-package");
                  }}
                >
                  Démenagement
                </li>

                <li
                  style={{
                    marginTop: "10px",
                    fontFamily: "Raleway-Extra-Bold-Italic",
                  }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Mon compte
                </li>
                <li
                  onClick={() => {
                    navigate("/chat");
                  }}
                  style={{
                    fontFamily: "Raleway-Extra-Bold-Italic",
                  }}
                >
                  Messagerie
                </li>
              </ul>
            </div>
            <div className="col">
              <span
                style={{
                  padding: 0,
                  fontSize: "14px",
                  color: "#fff",
                  fontFamily: "Raleway-Extra-Bold-Italic",
                }}
              >
                Transporteur
              </span>
              <ul>
                <li
                  onClick={() => {
                    navigate("/transporter-un-colis");
                  }}
                >
                  Devenir transporteur
                </li>
                <li
                  onClick={() => {
                    navigate("/announces");
                  }}
                >
                  Voir les annonces{" "}
                </li>
                {/* <li> une livraison</li> */}
              </ul>
            </div>
            {/* <div className="col">
                  <h1>Déménagement </h1>
                  <ul>
                    <li>Déménageur particulier</li>
                    <li>Déménageur professionnel</li>
                  </ul>
                </div> */}
            {/*
            comment by asad iqbal 1/14/2025 on Ben Demand ( Launching Frank C2C ppt)
            <div className="col">
              <span
                style={{
                  padding: 0,
                  fontSize: "14px",
                  color: "#fff",
                  fontFamily: "Raleway-Extra-Bold-Italic",
                }}
                onClick={() => {
                  navigate("/marketplace");
                }}
              >
                Marketplace
              </span>
              <ul></ul>
            </div> */}
            <div className="col">
              {/* <h1>Stockage colis</h1>
                  <ul>
                    <li>Trouver un point relais particulier </li>
                    <li> Proposer un espace de point relais</li>
                    <li>Consigner bagage</li> */}
              <ul>
                <li
                  style={{
                    // marginTop: "10px",
                    fontFamily: "Raleway-Extra-Bold-Italic",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    navigate("/blogs");
                  }}
                >
                  Blog
                </li>

                {/* 
                                comment by asad iqbal.Change from ppt (17/1/25)

                <li
                  style={{
                    fontFamily: "Raleway-Extra-Bold-Italic",
                    fontSize: "18px",
                  }}
                >
                  FAQ
                </li> */}
              </ul>
            </div>
            <div className="col" style={{ width: "190px", height: 200 }}>
              <div
                style={{
                  border: "1px solid #fff",
                  borderRadius: "12px",
                  marginTop: "20px",
                  padding: "20px",
                  width: "180px",
                }}
              >
                <span
                  style={{
                    padding: 0,
                    fontSize: "14px",
                    color: "#fff",
                    fontFamily: "Raleway-Extra-Bold-Italic",
                  }}
                >
                  Nous Contacter:
                </span>
                <ul>
                  <li style={{ textDecoration: "none", color: "#fff" }}>
                    {" "}
                    <a
                      style={{ textDecoration: "none", color: "#fff" }}
                      href="mailto:hello@frankme.com"
                    >
                      hello@frankme.com
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ textDecoration: "none", color: "#fff" }}
                      href="tel:+33607948055"
                    >
                      {" "}
                      +33 6 07 94 80 55
                    </a>
                  </li>
                  {/* <li>91 avenue de la République 75011 Paris</li> */}
                  <li>7 rue du 4 Septembre, 75002 Paris</li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "70px",
                  gap: "10px",
                  marginTop: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/profile.php?id=61561661727623",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  style={{
                    cursor: "pointer",
                    display: "inline-flex", // Ensure the image fills the div
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/profile.php?id=61561661727623",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                    src={facebookIcon}
                    alt="facebook-icon"
                    style={{
                      width: "100%", // Make image fill the div
                      height: "100%", // Adjust height if needed
                      display: "block", // Remove gaps around the image
                      zIndex: "11111",
                    }}
                  />
                </div>

                <div
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/fr-frank/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  style={{
                    cursor: "pointer",
                    display: "inline-flex", // Ensure the image fills the div
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    // onClick={() =>
                    //   window.open(
                    //     "https://www.linkedin.com/company/fr-frank/",
                    //     "_blank",
                    //     "noopener,noreferrer"
                    //   )
                    // }
                    src={instaIcon}
                    alt="facebook-icon"
                    style={{
                      width: "100%", // Make image fill the div
                      height: "100%", // Adjust height if needed
                      display: "block", // Remove gaps around the image
                      zIndex: "11111",
                    }}
                  />
                </div>

                <div
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/frankme.fr/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  style={{
                    cursor: "pointer",
                    display: "inline-flex", // Ensure the image fills the div
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/frankme.fr/",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                    src={linkedInIcon}
                    alt="linkedin-icon"
                    style={{
                      width: "100%", // Make image fill the div
                      height: "100%", // Adjust height if needed
                      display: "block", // Remove gaps around the image
                      zIndex: "11111",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="col"
              style={{
                width: "750px",
                marginLeft: "170px",
                fontFamily: "Raleway-Italic",
                fontSize: "14px",
                color: "#fff",
              }}
            >
              <span
                onClick={() => {
                  navigate("/privacy-policy");
                }}
                style={{ textDecoration: "none", color: "#fff",cursor:'pointer' }}
              >
                Politique de confidentialité
              </span>
              &nbsp;|{" "}
              <span
                style={{ textDecoration: "none", color: "#fff",cursor:'pointer' }}
                onClick={() => {
                  navigate("/terms-and-condition");
                }}
              >
                Termes et conditions
              </span>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomFooter;
