import { ExpandMore, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  DialogContent,
  Grid,
  InputAdornment,
  Rating,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  HomeIcon,
  MapHomeIcon,
  MapPointerIcon,
  NoAnnoucementImage,
  PointerIcon,
  RefrestIcon,
} from "../../assets/images/FrankIcons";
import apiServices from "../../services/RequestHandler";
import { strings } from "../../Store/Localization";
import CustomButton from "../../UiComponent/CustomButton";
import CustomFooter from "../../UiComponent/CustomFooter";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import GoogleAutocompleteField from "../../UiComponent/GoogleAutocompleteField";
import NewHeader from "../../UiComponent/NewHeader";
import Toast from "../../UiComponent/Toast/Toast";
import { apiKey, defaultLabelColors } from "../../utils/constants";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixedOne,
} from "../../utils/helper";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import AuthAction from "../Auth/AuthAction";
import { addDoc, collection } from "firebase/firestore";
import { firestoreDB } from "../../utils/notificationListner";
import FrankConstants from "../../utils/FrankConstants";
import { RootState } from "../../Store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove the border
    },
    "&:hover fieldset": {
      border: "none", // Remove hover border
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove focus border
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "52px",
    padding: "12px 14px", // Adjust padding if needed
    textAlign: "center",
    fontFamily: "Raleway-Variable",
    fontWeight: "700",
  },
  "& .MuiInputAdornment-root": {
    fontSize: `52px !important`,
    fontFamily: "Raleway-Variable", // To match the font family
    fontWeight: "700",
  },
});

function AllAnounces(props: any) {
  const { classes } = props;
  const navigate = useNavigate();
  const [inputValueForOrigin, setInputValueForOrigin] = useState("");
  const [inputValueForDestination, setInputValueForDestination] = useState("");
  // eslint-disable-next-line
  const [originLocationForMap, setOriginLocationForMap] = useState({});
  const selector = useSelector((state: RootState) => state.mainApp);

  // let user = getDataFromLocalStorage("user");
// eslint-disable-next-line
  const [destinationLocationForMap, setDestinationLocationForMap] = useState(
    {}
  );
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      announes: "All",
      initilalTotalFeedBack: 3,
    }
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setState({
        coords: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    });
  }, []);

  useEffect(() => {
    handleGetAllJobs(state?.announes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.announes]);
  const handleClearSearchFeilds = () => {
    try {
      setInputValueForOrigin("");
      setInputValueForDestination("");
      setOriginLocationForMap({});
      setDestinationLocationForMap({});
    } catch (error) {}
  };

  const handleGetAllJobs = async (jobType?: string) => {
    try {
      let user = getDataFromLocalStorage("user");

      setState({
        announes: jobType,
      });
      let body: any = {
        type: state?.announes,
        limit: false,
        user: user?.id ?? null,
      };
      if (jobType === "Around Me") {
        body = {
          ...body,
          lat: state?.coords?.lat,
          lng: state?.coords?.lng,
        };
      }
      const resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-announcements",
        body,
        ""
      );

      setState({
        allJobsData: resp?.data,
      });
      // const locations = state?.allJobsData?.pickup?.map(
      //   (item: any) => item.location
      // );
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetJobInfo = async (item?: any) => {
    try {
      let user = getDataFromLocalStorage("user");
      if (!user) {
        setState({ openLoginDialog: true });
        return;
      }

      let obj: any = {
        id: Number(item?.id),
        customer_id: Number(user?.id),
      };

      const resp = await apiServices.postFromApi(
        `c2c-shipments/details`,
        obj,
        ""
      );

      handleGetUserDetails(resp?.data);

      const accepter_details = resp?.data?.negotiations?.filter(
        (i: any) => Number(i?.accepter_id) === Number(user?.id)
      );

      accepter_details?.sort((a: any, b: any) =>
        new Date(a?.created_at).getTime() > new Date(b?.created_at).getTime()
          ? -1
          : 1
      );

      if (accepter_details?.[0]?.is_accepted === null) {
        setState({
          disableNegotiate: true,
          accepter_details,
        });
      }

      setState({
        jobDetails: resp?.data,
        openJobDialog: true,
        negotiated_price: resp?.data?.ship_price + 1,
      });
    } catch (error) {}
  };

  const handleGetUserDetails = async (item?: any) => {
    try {
      const resp = await apiServices.getFromApi(
        `c2c/get-user-details/${item?.user_details?.id}`,
        ""
      );

      let avg_rating_ = resp.data?.ratings2?.reduce((a: any, b: any) => {
        return a + b.rating;
      }, 0);
      let avg_rating = isNaN(avg_rating_ / resp?.data?.ratings2?.length)
        ? 0
        : avg_rating_ / resp?.data?.ratings2?.length;
      setState({
        allRatings: resp?.data,

        details: {
          ...resp.data,
          avg_rating,
        },
      });
    } catch (error) {}
  };

  const onInputChange = (e: any) => {
    try {
      let { value, name } = e.target;

      const re = /^\d*\.?\d{0,2}$/;

      if (value === "") {
        setState({
          [name]: value,
        });
        return;
      }

      if (
        !re.test(value) ||
        value[0] === " " ||
        parseFloat(value) < 0 ||
        value[0] === "."
      ) {
        return;
      }

      if (value.length > 1 && value[0] === "0" && !value.includes(".")) {
        return;
      }

      if (parseFloat(value) > 10000) {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {
      console.error("Error in onInputChange:", error);
    }
  };

  const handleAcceptJob = async () => {
    try {
      let obj = {
        ship_id: Number(state?.jobDetails?.id),
        ship_price: Number(state?.jobDetails?.ship_price),
        is_accepted: true,
      };
      let resp = await apiServices.postFromApi(
        "c2c-shipment-negotiations",
        obj,
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        Toast.showSuccess("annonce acceptée");
        handleSentFirstMessage(Number(state?.jobDetails?.ship_price));
        navigate(`/chat/${state?.jobDetails?.id}`);
        setState({ openJobDialog: false });
        handleGetAllJobs();
      }
    } catch (error) {}
  };

  const handleNegotiate = async (negotiatedValue?: Number, shipID?: Number) => {
    if (
      state.negotiated_price < state.jobDetails?.ship_price ||
      !state.negotiated_price
    ) {
      Toast.Error("Le montant n’est pas valide");
      return;
    }
    handleSentFirstMessage();
  };

  const handleSentFirstMessage = async (amount?: number) => {
    let user = getDataFromLocalStorage("user");

    const name = user?.first_name + user?.surname ? ` ${user?.surname}` : "";
    let first_msg: any = {
      message: ``,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: state.jobDetails,
      sender_id: `${user?.id}`,
      receiver_id: `${state.jobDetails?.customer_id}`,
      sender_name: name,
    };
    let new_msg: any = {
      message: `${name} has sent you a message. neg_amt=${
        amount ?? state?.negotiated_price
      }`,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: null,
      sender_id: `${user?.id}`,
      receiver_id: `${state.jobDetails?.customer_id}`,
      sender_name: name,
    };
    if (amount) {
      await addDoc(collection(firestoreDB, `chat_negotiations`), first_msg);
      await addDoc(collection(firestoreDB, `chat_negotiations`), new_msg);

      return;
    }

    let obj: any = {
      ship_id: Number(
        state.jobDetails?.id
          ? state.jobDetails?.id
          : state?.jobDetails?.id ?? state?.jobDetails?.ship_id
      ),
      ship_price: Number(state.negotiated_price ?? 0),
    };

    if (state?.jobDetails?.negotiations?.length > 0) {
      obj.parent_id = Number(
        state?.jobDetails?.negotiations?.[
          state?.jobDetails?.negotiations?.length - 1
        ]?.id
      );
    }

    const resp = await apiServices.postFromApi(
      "/c2c-shipment-negotiations",
      obj,
      ""
    );

    if (resp.status === 201) {
      Toast.showSuccess(strings.offer_request_sent);
      handleGetAllJobs(state?.announes);
      await addDoc(collection(firestoreDB, `chat_negotiations`), first_msg);
      await addDoc(collection(firestoreDB, `chat_negotiations`), new_msg);

      setState({ messagingScreen: true });
      handleGetAllJobs();
    } else {
      Toast.Error(
        resp.error?.message ??
          resp?.message ??
          resp?.error?.error ??
          "Internal Server Error"
      );
    }
  };

  const handleCloseLoginPage = () => {
    try {
      setState({ openLoginDialog: false });
    } catch (error) {}
  };

  const handlePlaceSelectedDestination = async (
    place: google.maps.places.PlaceResult | null
  ) => {
    if (place && place.geometry) {
      const latitude = place.geometry.location?.lat() || 0;
      const lngitude = place.geometry.location?.lng() || 0;
      const address = place.formatted_address || "";
      // setSelectedLocation({ lat, lng, address });
      setInputValueForDestination(address);
      const lat = latitude;
      const lng = lngitude;
      let originLoc = { lat, lng };

      setState({
        destinationLocation: `${lat},${lng}`,
        destinatioForMap: originLoc,
      });
    }
  };

  const handlePlaceSelected = async (
    place: google.maps.places.PlaceResult | null
  ) => {
    if (place && place.geometry) {
      const latitude = place.geometry.location?.lat() || 0;
      const lngitude = place.geometry.location?.lng() || 0;
      const address = place.formatted_address || "";
      // setSelectedLocation({ lat, lng, address });
      searchJobByOrignin(place);
      setInputValueForOrigin(address);
      const lat = latitude;
      const lng = lngitude;
      let originLoc = { lat, lng };
      setState({
        originLocation: `${latitude},${lngitude}`,
        originForMap: originLoc,
        origin_city:place?.name
      });
    }
  };

  const handleTerminer = () => {
    try {
      setState({
        openJobDialog: false,
        messagingScreen: false,
        negotiate: false,
        disableNegotiate: false,
        accepter_details: [],
      });
      handleGetAllJobs(state?.announes);
    } catch (error) {}
  };

  useEffect(() => {
    if (inputValueForOrigin === "" && inputValueForDestination === "") {
      setOriginLocationForMap({});
      setDestinationLocationForMap({});
      setInputValueForOrigin("");
      setInputValueForDestination("");
      handleGetAllJobs("All");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueForOrigin, inputValueForDestination]);

  // useEffect(() => {
  //   handleApi();
  // }, [state.decoded_polyline]);

  // const handleApi = async () => {
  //   let resp;
  //   if (state.decoded_polyline) {
  //     if (state.decoded_polyline?.length === 1) {
  //       let cor = state.decoded_polyline[0];

  //       let obj = {
  //         data: {
  //           range: 5000,
  //           type: "Point",
  //           coordinates: cor,
  //           user: user?.id ?? null,
  //         },
  //       };
  //       resp = await apiServices.postFromApi(
  //         "c2c-shipments/web-all-locations",
  //         obj,
  //         ""
  //       );

  //       if (resp?.data?.error) {
  //         Toast.Error("Please Select Valid Start and End Point");
  //         return;
  //       }
  //       setState({ allJobsData: resp?.data });
  //     }
  //     if (state.decoded_polyline?.length > 1) {
  //       let obj = {
  //         data: {
  //           range: 5000,
  //           type: "LineString",
  //           coordinates: state.decoded_polyline,
  //           user: user?.id ?? null,
  //         },
  //       };
  //       resp = await apiServices.postFromApi(
  //         "c2c-shipments/web-all-locations",
  //         obj,
  //         ""
  //       );

  //       if (resp?.data?.error) {
  //         Toast.Error("Please Select Valid Start and End Point");
  //         return;
  //       }
  //       setState({ allJobsData: resp?.data });
  //     }
  //   }
  // };

  const handleGetDirections = async () => {
    try {
      if (!state?.originLocation) {
        Toast.Error("Veuillez sélectionner un lieu de départ valide");
        return;
      }
      if (!state?.destinationLocation) {
        Toast.Error("Veuillez sélectionner un emplacement final valide");
        return;
      }
      let direction_query = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${state.originForMap?.lng}%2C${state.originForMap?.lat}%3B${state.destinatioForMap?.lng}%2C${state.destinatioForMap?.lat}?alternatives=false&annotations=distance%2Cduration%2Cspeed%2Ccongestion%2Cmaxspeed%2Cclosure%2Ccongestion_numeric&banner_instructions=true&geometries=geojson&language=en&overview=full&roundabout_exits=true&steps=true&access_token=pk.eyJ1IjoiZnJhbmtmciIsImEiOiJjbTVhcWl5OW4wZzZvMmpzNm5iYzUwNWF5In0.X7R4cjq4TJb1rF0XXqbTSQ`;

      const line_string = await axios(direction_query);
      const user = getDataFromLocalStorage("user");

      let line_string2: any = JSON.stringify(line_string?.data);
      line_string2 = JSON.parse(line_string2);

       if (line_string2?.routes < 1 || !line_string2?.routes) {
              Toast.Error("Aucun itinéraire possible disponible");
              return;
            }
      let obj = {
        data: {
          lineString: `LINESTRING(${line_string2?.routes?.[0]?.geometry?.coordinates
            ?.map((i: any) => i.reverse())
            ?.map((coord: any) => coord.join(" "))
            .join(", ")})`,
          type: "LineString",
          city:state?.origin_city?.name,
          range: 1000,
          user: user?.id ?? null,
        },
      };
      const resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-locations",
        obj,
        ""
      );

      if (resp?.data?.error) {
        Toast.Error("Veuillez sélectionner un point de début et de fin valide");
        return;
      }
      setState({
        allJobsData: resp?.data,
      });

      // let decoded_data = await getDirections(
      //   state?.originLocation,
      //   state?.destinationLocation
      // );
      setOriginLocationForMap(state?.originForMap);
      setDestinationLocationForMap(state?.destinatioForMap);
      // let resp = null;
      // handleApi();
      // if (decoded_data) {
      //   if (decoded_data?.length > 1) {
      //     let obj = {
      //       data: {
      //         range: 5000,
      //         type: "LineString",
      //         coordinates: decoded_data,
      //       },
      //     };
      //     resp = await apiServices.postFromApi(
      //       "c2c-shipments/web-all-locations",
      //       obj,
      //       ""
      //     );

      //     if (resp?.data?.error) {
      //       Toast.Error("Please Select Valid Start and End Point");
      //       return;
      //     }
      //     setState({ allJobsData: resp?.data });
      //   }
      // }
    } catch (error) {}
  };

  const transSizes = (item: any) => {
    const deliveryType = FrankConstants?.packageSize(selector.lang)?.find(
      (a: any) => a.size === item
    );
    return deliveryType?.size_trans;
  };

  const transDelivery = (item: any) => {
    const deliveryType = FrankConstants?.deliveryTypes(selector.lang)?.find(
      (a: any) => a.key === item
    );
    return deliveryType?.label;
  };

  const handleRedirectToChat = () => {
    try {
      navigate(`/chat/${state?.respData?.ship_id}`);
    } catch (error) {}
  };

  const searchJobByOrignin = async (data: any) => {
      try {
        const user = getDataFromLocalStorage("user");
  
        // return;
        let obj = {
          searched_location: data?.name,
          lat: data?.geometry.location?.lat() || 0,
          lng: data?.geometry.location?.lng() || 0,
          user: user?.id ?? null,
        };
        let resp = await apiServices.postFromApi(
          "/c2c-shipments/search-by-city",
          obj,
          ""
        );
        console.log(resp);
        setState({ allJobsData: resp?.data?.data });
      } catch (error) {}
    };

  return (
    <>
      <Helmet>
        <title>Toutes les Annonces | Livraison Collaborative avec Frank</title>
        <link
          rel="canonical"
          href="https://www.v2internal.frankme.com/announces"
        />
        <link
          rel="icon"
          type="image/ico"
          href="../../assets/images/FrankIcons/favicon.ico"
        />
        <meta
          name="description"
          content="Découvrez toutes les annonces de livraison collaborative sur Frank. Trouvez des offres pour envoyer ou transporter des colis facilement et à moindre coût."
        />
      </Helmet>

      <div className="web-screen">
        <div style={{ background: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>

          <div style={{ padding: "20px 140px 0px 140px" }}>
            <Grid container spacing={2}>
              <Grid xs={12} md={12} item>
                <div>
                  <h1 className="page-title-one">{strings?.announcements} </h1>
                </div>
                {/* 
                comment by asad iqbal. change from ppt (17/1/25)
                <div
                  style={{
                    display: "flex",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <div style={{ width: "150px" }}>
                    <img
                      src={transporterUnColisImage}
                      height={150}
                      width={150}
                      alt="transporter-img"
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        color: "#000",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                    >
                      <span style={{ color: defaultLabelColors.main_blue }}>
                        {" "}
                        Gagnez de l'argent{" "}
                      </span>{" "}
                      en transportant des colis entre particuliers
                    </p>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "14px",
                      }}
                    >
                      Sélectionnez, transportez, encaissez ! Rien de plus simple
                    </p>
                  </div>
                </div> */}
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid xs={12} md={12} item>
                <div>
                  <p
                    style={{
                      fontFamily: "Raleway-Italic",
                      fontWeight: "700",
                      fontSize: "22px",
                      color: "#000",
                    }}
                  >
                    Votre trajet
                  </p>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      width: "100%",
                      gap: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <div
                      style={{
                        display: "none",
                      }}
                    >
                      <GoogleMaps
                        handleGetLocation={(data) => {}}
                        markers_data={state?.allJobsData ?? []}
                        origin={originLocationForMap}
                        destination={destinationLocationForMap}
                        handleGetRouteLog={(data) => {
                          setState({
                            decoded_polyline: data,
                          });
                        }}
                      />
                    </div> */}

                    <div style={{ width: "100%" }}>
                      <GoogleAutocompleteField
                        apiKey={apiKey}
                        placeholder="Lieu de départ"
                        inputValue={inputValueForOrigin}
                        onInputChange={setInputValueForOrigin}
                        onPlaceSelected={handlePlaceSelected}
                        key={"selectedLocation"}
                        styles={{
                          background: "#F4F3F6",
                          width: "96.5%",
                          borderRadius: "16px",
                          border: "none",
                          fontSize: "16px",
                          margin: "10px",
                          fontFamily: "Raleway-Italic",
                          padding: "10px",
                          marginLeft: 9,
                          zIndex: 1111,
                          color: "#000",
                          height: "41px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "10px",
                          paddingLeft: 12,
                        }}
                      >
                        <div>
                          <HomeIcon height={55} width={55} />
                        </div>
                        <div
                          style={{
                            height: "2px",
                            borderTop: "2px dashed grey",
                            width: "30px",
                          }}
                        ></div>
                        <div>
                          <PointerIcon height={55} width={55} />
                        </div>
                      </div>
                    </div>

                    <div style={{ width: "100%" }}>
                      <GoogleAutocompleteField
                        apiKey={apiKey}
                        placeholder="Lieu d’arrivée"
                        inputValue={inputValueForDestination}
                        onInputChange={setInputValueForDestination}
                        onPlaceSelected={handlePlaceSelectedDestination}
                        key={"selectedLocation"}
                        styles={{
                          background: "#F4F3F6",
                          width: "96.5%",
                          borderRadius: "16px",
                          border: "none",
                          fontSize: "16px",
                          fontFamily: "Raleway-Italic",
                          padding: "10px",
                          zIndex: 1111,
                          color: "#000",
                          height: "41px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        marginTop: "10px",
                        background:
                          inputValueForOrigin && inputValueForDestination
                            ? "#ee6931"
                            : "lightgrey",
                        border: "none",
                        borderRadius: "12px",
                        padding: "10px 50px 10px 50px",
                        height: "100%",
                        minHeight: "51px",
                      }}
                      disable={
                        inputValueForOrigin && inputValueForDestination
                          ? false
                          : true
                      }
                      startIcon={<Search />}
                      value={strings?.search}
                      onClick={handleGetDirections}
                    />{" "}
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ paddingTop: "20px" }}>
              <Grid
                md={6}
                xs={12}
                item
                style={{
                  flex: 1,
                  fontSize: "20px",
                  fontWeight: "700",
                  fontFamily: "Raleway-Variable",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                Annonces sur le trajet
                <p>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFamily: "Raleway-Variable",
                      paddingLeft: "100px",
                    }}
                  >
                    {state?.allJobsData?.length ?? ""} annonces en ligne{" "}
                  </div>
                </p>
              </Grid>

              <Grid
                md={6}
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    value={"Tout"}
                    classesNames={
                      state?.announes === "All"
                        ? "contained_button"
                        : "outlined_button "
                    }
                    onClick={() => {
                      setState({ announes: "All" });
                      handleClearSearchFeilds();
                    }}
                  />
                  <CustomButton
                    value={"Urgent"}
                    classesNames={
                      state?.announes === "Urgent"
                        ? "contained_button"
                        : "outlined_button "
                    }
                    onClick={() => {
                      setState({ announes: "Urgent" });
                      handleClearSearchFeilds();
                    }}
                  />
                  <CustomButton
                    value={"Autour de moi"}
                    classesNames={
                      state?.announes === "Around Me"
                        ? "contained_button"
                        : "outlined_button "
                    }
                    onClick={() => {
                      setState({ announes: "Around Me" });
                      handleClearSearchFeilds();
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ marginTop: "10px", paddingBottom: "20px" }}
            >
              {state?.allJobsData?.length < 1 ? (
                <div
                  style={{
                    width: "100%",
                    fontSize: "32px",
                    fontWeight: "700",
                    // opacity: "0.5",
                    textAlign: "center",
                    fontFamily: "Raleway-Variable",
                    marginTop: "20px",
                  }}
                >
                  <p>Aucune annonce trouvée</p>
                  {/* <img
                    src={noJobsImage}
                    alt="not-found"
                    style={{ opacity: "0.5" }}
                  /> */}
                  <NoAnnoucementImage width={"100%"} height={"50vh"} />
                </div>
              ) : (
                state?.allJobsData?.map((i: any) => {
                  return (
                    <Grid xs={12} sm={12} md={4} lg={3} item>
                      <div
                        style={{
                          background: "#fff",
                          borderRadius: "16px",
                          padding: "20px",
                          // height: "250px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleGetJobInfo(i);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <p
                              style={{
                                fontSize: "16px",
                                fontFamily: "Raleway-Italic",
                                color: "#000",
                                fontWeight: "700",
                              }}
                              className="title-text-announces"
                            >
                              <Tooltip
                                title={`${
                                  i?.shipment_items[0]?.item_name_fr ??
                                  i?.prod_name ??
                                  "city"
                                } `}
                              >
                                {i?.shipment_items[0]?.item_name_fr ??
                                  i?.prod_name ??
                                  "Arbre a chat"}
                              </Tooltip>

                              <br />
                              <span
                                style={{
                                  fontFamily: "Raleway-Variable",
                                  fontSize: "12px",
                                  color: "grey",
                                }}
                              >
                                Publié le{" "}
                                {moment(i?.created_at).format("DD/MM/YYYY")}
                              </span>
                            </p>
                          </div>
                          <div
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "24px",
                              fontWeight: "800",
                              color: defaultLabelColors.main_orange,
                            }}
                          >
                            {i?.ship_price ?? "0.00"}€
                          </div>
                        </div>

                        <div
                          style={{
                            height: "100px",
                            width: "100%",
                            borderRadius: "15px 15px 0px 0px",
                          }}
                        >
                          <img
                            src={i?.shipment_items[0]?.prod_images[0]}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                              borderRadius: "15px 15px 0px 0px",
                            }}
                            alt="prod-img"
                          />
                        </div>

                        <div
                          style={{
                            background: "#F4F3F6",
                            borderRadius: "0px 0px 15px 15px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              borderBottom: "1px solid #3026f1",
                            }}
                          >
                            <p
                              style={{
                                background: "#f9c9b3",
                                borderRadius: 100,
                                padding: "10px 12px",
                              }}
                            >
                              <MapHomeIcon
                                fill={defaultLabelColors.main_blue}
                                height={20}
                                width={20}
                              />
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "16px",
                                }}
                              >
                                Départ
                              </span>
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontWeight: "700",
                                  wordBreak: "break-all",
                                  fontSize: "12px",
                                  display: "inline-block",
                                  maxWidth: "120px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Tooltip
                                  title={`${i?.pickup?.city ?? "city"}, ${
                                    i?.pickup?.country ?? "country"
                                  }`}
                                >
                                  <span>
                                    {i?.pickup?.city ?? "City"}{" "}
                                    {i?.pickup?.country ?? "Country"}
                                  </span>
                                </Tooltip>
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <p
                              style={{
                                background: "#f9c9b3",
                                borderRadius: 100,
                                padding: "10px 13px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <MapPointerIcon
                                fill={defaultLabelColors.main_orange}
                              />
                            </p>
                            <p
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontSize: "16px",
                              }}
                            >
                              Arrivée
                              <br />
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontWeight: "700",
                                  wordBreak: "break-all",
                                  fontSize: "12px",
                                  display: "inline-block",
                                  maxWidth: "120px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Tooltip
                                  title={`${i?.dropoff?.city ?? "city"}, ${
                                    i?.dropoff?.country ?? "country"
                                  }`}
                                >
                                  <span>
                                    {i?.dropoff?.city ?? "City"},
                                    {i?.dropoff?.country ?? "country"}
                                  </span>
                                </Tooltip>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </div>
        </div>
        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>

        <DraggableDialog
          maxWidth={`${state?.negotiate ? "sm" : "md"}`}
          open={state?.openJobDialog}
          handleClose={() => {
            setState({
              openJobDialog: false,
              messagingScreen: false,
              negotiate: false,
              disableNegotiate: false,
              accepter_details: [],
              negotiated_price: "",
            });
          }}
          dialog_title={"Transporter un colis"}
          classes={classes}
          dialogBody={
            <DialogContent style={{}} dividers>
              {state?.negotiate ? (
                state?.messagingScreen ? (
                  <>
                    <div
                      className="card-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "92%",
                        padding: "20px",
                        height: "60vh",
                      }}
                    >
                      <div style={{ flex: 1, width: "100%", gap: 20 }}>
                        <div
                          style={{
                            background: "#eef2f6",
                            borderRadius: "16px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            marginTop: "10px",
                            width: "90%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              borderBottom: "1px solid #3026f1",
                            }}
                          >
                            <p
                              style={{
                                background: "#f9c9b3",
                                borderRadius: "32px",
                                padding: "10px 15px",
                              }}
                            >
                              <MapHomeIcon
                                fill={defaultLabelColors.main_blue}
                                height={20}
                                width={20}
                              />
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "16px",
                                }}
                              >
                                Départ
                              </span>
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontWeight: "700",
                                  wordBreak: "break-all",
                                  fontSize: "15px",
                                }}
                              >
                                {" "}
                                {state?.jobDetails?.pickup?.city ?? "City"},
                                {state?.jobDetails?.pickup?.country ?? "City"}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <p
                              style={{
                                background: "#f9c9b3",
                                borderRadius: "32px",
                                padding: "10px 15px",
                              }}
                            >
                              <MapPointerIcon
                                fill={defaultLabelColors.main_blue}
                              />
                            </p>
                            <p
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontSize: "16px",
                              }}
                            >
                              Destination
                              <br />
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontWeight: "700",
                                  wordBreak: "break-all",
                                  fontSize: "15px",
                                }}
                              >
                                {" "}
                                {state?.jobDetails?.dropoff?.city ?? "City"},
                                {state?.jobDetails.dropoff?.country ?? "City"}
                              </span>
                            </p>
                          </div>

                          <div
                            style={{
                              borderTop: "1px solid lightgrey",
                              padding: "10px",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: "18px",
                                fontWeight: 600,
                                flex: 1,
                              }}
                            >
                              Frais de livraison
                            </div>
                            <div
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: "18px",
                                fontWeight: 600,
                              }}
                            >
                              {numberFormatWithoutCurrency(
                                state?.jobDetails?.ship_price
                              )}{" "}
                              €
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            background: "#DCDBFB",
                            borderRadius: "16px",
                            width: "94%",
                            padding: "10px",
                            fontFamily: "Raleway-Variable",
                            fontWeight: "600",
                            marginTop: "20px",
                            fontSize: "16px",
                          }}
                        >
                          vous avez fait une offre pour un montant de :{" "}
                          {numberFormatWithoutCurrency(state?.negotiated_price)}{" "}
                          €
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        gap: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      <CustomButton
                        classesNames={"outlined_button"}
                        value={"Retour"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "200px" }}
                        onClick={handleTerminer}
                      />
                      <CustomButton
                        classesNames={"contained_button"}
                        value={"Terminer"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "200px" }}
                        onClick={handleRedirectToChat}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <div>
                      <div className="card-container">
                        <img
                          src={
                            state?.jobDetails?.shipment_items[0]?.prod_images[0]
                          }
                          className="card-image"
                          alt="prod-img"
                        />
                        <div
                          className="card-details"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="card-title"
                            style={{ fontSize: 20, flex: 1 }}
                          >
                            {handleLocalize({
                              en: state?.jobDetails?.shipment_items[0]
                                ?.item_name_en,
                              fr: state?.jobDetails?.shipment_items[0]
                                ?.item_name_fr,
                            }) ??
                              state?.jobDetails?.shipment_items[0]?.prod_name ??
                              ""}
                          </div>

                          <div
                            className="card-quantity"
                            style={{
                              color: defaultLabelColors.main_orange,
                              display: "flex",
                              alignItems: "center",
                              fontSize: "26px",
                              fontWeight: 600,
                              paddingRight: "10px",
                            }}
                          >
                            {" "}
                            {state?.jobDetails?.ship_price} €
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        lineHeight: "1px",
                        marginTop: "20px",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "20px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Insérez le montant de votre offre
                      </p>

                      <div className="display-flex">
                        <StyledTextField
                          autoComplete="off"
                          name="negotiated_price"
                          type="text"
                          onChange={onInputChange}
                          placeholder="ex : 8"
                          value={state.negotiated_price ?? ""}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{
                                  fontSize: "52px",
                                  fontFamily: "Raleway-Variable",
                                  fontWeight: "700",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Raleway-Variable",
                                    fontSize: "53px",
                                    position: "absolute",
                                    left: "calc(100% - 35%)",
                                  }}
                                >
                                  €
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        gap: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      <CustomButton
                        classesNames={"outlined_button"}
                        value={"Annuler"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "100%" }}
                        onClick={() => {
                          setState({ negotiate: false });
                        }}
                      />
                      <CustomButton
                        classesNames={"contained_button"}
                        value={"Négocier"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "100%" }}
                        onClick={handleNegotiate}
                      />
                    </div>
                  </div>
                )
              ) : (
                <>
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: 20,
                    }}
                  >
                    <div style={{ display: "flex", paddingBottom: 20 }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Détail de l’expéditeur
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Membre depuis &nbsp;
                        <span style={{ fontWeight: "600" }}>
                          {moment(
                            state?.jobDetails?.user_details?.created_at
                          ).format("YYYY") ?? "recently"}
                        </span>
                      </div>
                    </div>
                    <Accordion
                      elevation={0}
                      sx={{
                        "&::before": {
                          display: "none",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <AccordionSummary
                        style={{
                          marginTop: -20,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                width: 100,
                                height: 100,
                                borderRadius: 100,
                                overflow: "hidden",
                                display: "flex",

                                boxShadow: 3,
                              }}
                            >
                              <img
                                src={
                                  state?.jobDetails?.user_details
                                    ?.profile_pic ??
                                  "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                                }
                                alt="Profile"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </Box>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "flex-end",
                              paddingLeft: 10,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "Raleway-Bold",
                                  fontSize: "18px",
                                }}
                              >
                                {state?.jobDetails?.user_details?.cus_name ??
                                  "Customer Name"}
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 10,
                                }}
                              >
                                <Stack spacing={1}>
                                  <Rating
                                    style={{ borderRadius: 12 }}
                                    name="half-rating-read"
                                    defaultValue={
                                      state?.jobDetails?.user_details
                                        ?.cus_ratings ?? 2
                                    }
                                    precision={0.5}
                                    readOnly
                                    size="large"
                                  />
                                </Stack>

                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Raleway-Semi-Bold",
                                    width: "100%",
                                  }}
                                >
                                  {`( ${
                                    numberFormatWithoutCurrencyFixedOne(
                                      state?.jobDetails?.user_details?.cus_ratings?.toFixed(
                                        1
                                      )
                                    ) ?? "0"
                                  } )`}
                                </span>
                                <span
                                  style={{
                                    fontFamily: "Raleway-Bold",
                                    color: defaultLabelColors.main_blue,
                                    fontSize: 18,
                                    paddingLeft: 10,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  Évaluation{" "}
                                  <ExpandMore
                                    style={{
                                      color: defaultLabelColors.main_blue,
                                      fontWeight: 600,
                                    }}
                                  />
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>

                      <AccordionDetails style={{ marginTop: "10px" }}>
                        {state?.allRatings?.ratings2?.length < 1 ? (
                          <p
                            style={{
                              fontFamily: "Raleway-Semi-Bold",
                              fontSize: 26,
                            }}
                          >
                            No Review Received yet!
                          </p>
                        ) : (
                          state?.allRatings?.ratings2
                            ?.slice(0, state?.initilalTotalFeedBack)
                            ?.map((i: any) => {
                              return (
                                <div
                                  style={{
                                    width: "100%",
                                    marginTop: 10,
                                    borderBottom: `${
                                      state?.allRatings?.ratings2?.length < 2
                                        ? "0px"
                                        : "1px"
                                    } solid #E0E0E0`,
                                    paddingBottom: 5,
                                    display: "flex",
                                    gap: 10,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: 60,
                                      height: 60,
                                      borderRadius: "50%",
                                      overflow: "hidden",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      boxShadow: 3,
                                      border: "1px solid #E0E0E0",
                                    }}
                                  >
                                    <img
                                      src={
                                        i?.profile_pic ??
                                        "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                                      }
                                      alt="Profile"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </Box>
                                  <div style={{ flex: 1, paddingTop: 10 }}>
                                    <p
                                      style={{
                                        fontFamily: "Raleway-Semi-Bold",
                                        fontSize: 14,
                                        color: defaultLabelColors.main_blue,
                                        margin: 0,
                                      }}
                                    >
                                      {i?.added_by ?? ""}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      <Stack spacing={1}>
                                        <Rating
                                          style={{ borderRadius: 12 }}
                                          name="half-rating-read"
                                          defaultValue={i?.rating ?? 2}
                                          precision={0.5}
                                          readOnly
                                          size="small"
                                        />
                                      </Stack>
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        paddingTop: 5,
                                        fontSize: "14px",
                                        fontFamily: "Raleway-Italic",
                                      }}
                                    >
                                      {i?.feedback ?? ""}
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      fontSize: 14,
                                      color: "#757575",
                                      fontFamily: "Raleway-Italic",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {moment(i?.created_at).fromNow()}{" "}
                                  </div>
                                </div>
                              );
                            })
                        )}
                        {state?.allRatings?.ratings2?.length > 6 ? (
                          <Grid xs={12} item className="display-flex">
                            <p
                              className="profile-headings display-flex"
                              style={{
                                color: "#3026f1",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setState({
                                  initilalTotalFeedBack:
                                    state?.initilalTotalFeedBack + 3,
                                });
                              }}
                            >
                              <RefrestIcon /> Plus de reviews
                            </p>
                          </Grid>
                        ) : null}
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "0px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      {strings?.type_of_delivery}
                    </p>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                          fontSize: "24px",
                        }}
                      >
                        {transDelivery(state?.jobDetails?.ship_delivery_type) ??
                          "Express"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Annonce créée le{" "}
                        {moment(state?.jobDetails?.created_at).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "15px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: "#F4F3F6",
                        borderRadius: "24px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{ display: "flex", padding: "10px", gap: 10 }}
                      >
                        <div style={{ flex: 1 }} className="display-flex">
                          <HomeIcon height={36} />
                        </div>
                        <div>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px" }}
                          >
                            {strings?.pickup_address}
                          </p>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {state?.jobDetails?.pickup?.address ??
                              "Paris, France"}
                            &nbsp;
                            {state?.jobDetails?.pickup?.city ?? ""},
                            {state?.jobDetails?.pickup?.country ?? "Paris"}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", padding: "10px", gap: 10 }}
                      >
                        <div style={{ flex: 1 }} className="display-flex">
                          <PointerIcon height={36} />
                        </div>
                        <div>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px" }}
                          >
                            {strings?.dropoff_address}
                          </p>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {state?.jobDetails?.dropoff?.address ??
                              "Beausoleil, france"}
                            &nbsp;
                            {state?.jobDetails?.dropoff?.city ??
                              "Beausoleil, france"}
                            ,
                            {state?.jobDetails?.dropoff?.country ??
                              "Beausoleil, france"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "0px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      {strings?.item_sizes}
                    </p>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 16,
                      }}
                    >
                      {state?.jobDetails?.shipment_items?.map((i: any) => {
                        return (
                          <div className="card-container">
                            <img
                              src={i?.prod_images[0]}
                              className="card-image"
                              alt="prod-img"
                            />
                            <div className="card-details">
                              <div
                                className="card-title"
                                style={{ fontSize: 17 }}
                              >
                                {handleLocalize({
                                  en: i?.item_name_en,
                                  fr: i?.item_name_fr,
                                }) ??
                                  i?.prod_name ??
                                  ""}
                              </div>

                              <div className="card-quantity">
                                x{i?.prod_quantity}
                              </div>
                              {i?.prod_is_fragile ? (
                                <div
                                  className="card-quantity"
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Raleway-Variable",
                                    fontWeight: "500",
                                    marginTop: 5,
                                  }}
                                >
                                  <span style={{ fontWeight: 600 }}>
                                    Fragile
                                  </span>{" "}
                                  : manipuler avec soin
                                </div>
                              ) : null}
                            </div>
                            <div
                              className="card-quantity"
                              style={{ paddingRight: "20px" }}
                            >
                              {transSizes(i?.cat_name)}
                              {i?.prod_quantity > 1 ? "s" : null}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {state?.accepter_details?.[0]?.is_accepted === null ? (
                    <div
                      style={{
                        borderRadius: "16px",
                        border: "1px solid #E0E0E0",
                        padding: "20px 20px 20px 20px",
                        gap: 10,
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontWeight: "700",
                            fontSize: "24px",
                          }}
                        >
                          Frais de livraison négociés
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "600",
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                            color: "grey",
                            gap: 20,
                          }}
                        >
                          {numberFormatWithoutCurrency(
                            state?.jobDetails?.ship_price
                          )}{" "}
                          €
                          <span style={{ color: defaultLabelColors.main_blue }}>
                            {numberFormatWithoutCurrency(
                              state?.accepter_details[0]?.ship_price
                            )}{" "}
                            €
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        borderRadius: "16px",
                        border: "1px solid #E0E0E0",
                        padding: "20px 20px 20px 20px",
                        gap: 10,
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontWeight: "700",
                            fontSize: "24px",
                          }}
                        >
                          {"Frais de livraison"}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                            color: defaultLabelColors.main_blue,
                          }}
                        >
                          {numberFormatWithoutCurrency(
                            state?.jobDetails?.ship_price
                          )}{" "}
                          €
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setState({ negotiate: true });
                      }}
                      className={
                        state?.disableNegotiate
                          ? "outlined_button_disable"
                          : "outlined_button"
                      }
                      disabled={state?.disableNegotiate ? true : false}
                      style={{ width: "200px", textTransform: "capitalize" }}
                      variant="contained"
                    >
                      {strings?.negotiate}
                    </Button>

                    <CustomButton
                      classesNames={"contained_button"}
                      value={strings?.accept}
                      // disable={state?.items?.length > 0 ? true : false}
                      style={{ width: "200px" }}
                      onClick={handleAcceptJob}
                    />
                  </div>
                </>
              )}
            </DialogContent>
          }
        />
        <DraggableDialog
          maxWidth={"md"}
          open={state?.openLoginDialog}
          handleClose={() => {
            setState({ openLoginDialog: false });
          }}
          classes={classes}
          dialogBody={
            <DialogContent
              style={{
                display: "flex",
                background: "#FCE6DD",
              }}
              dividers
            >
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <AuthAction
                    {...state}
                    {...props}
                    handleCloseLoginPage={handleCloseLoginPage}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          }
        />
      </div>
    </>
  );
}

export default AllAnounces;
